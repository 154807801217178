<template>
	<div>
		<Header :title="$t('Home.services.doctor.title')" :description="$t('Home.services.doctor.content')" />
		<router-view />
	</div>
</template>
<script>
import Header from '@/components/layout/Header';

export default {
	data() {
		return {};
	},
	components: {
		Header,
	},
	created() {
		this.$store.dispatch('getBranches');
	},
};
</script>
