<template>
  <div
    id="app"
    :style="this.$i18n.locale == 'ar' ? 'direction: rtl' : 'direction: ltr'"
  >
    <Nav />

    <router-view />

    <transition name="fade">
      <div
        v-if="successMsg"
        class="
          fixed
          z-50
          inset-0
          flex
          items-start
          justify-center
          text-center
          w-screen
          h-screen
        "
        style="background: rgba(0, 0, 0, 0.3)"
      >
        <SuccessMsg v-if="successMsg" />
      </div>
    </transition>
    <transition name="fade">
      <div
        v-if="errorMsg"
        class="
          fixed
          z-50
          inset-0
          flex
          items-start
          justify-center
          text-center
          w-screen
          h-screen
        "
        style="background: rgba(0, 0, 0, 0.3)"
      >
        <errorAler v-if="errorMsg" />
      </div>
    </transition>
    <Footer />
  </div>
</template>
<script>
import Nav from "./components/layout/Nav";
import SuccessMsg from "./components/layout/SuccessMsg";
import errorAler from "./components/layout/errorAler";
import Footer from "./components/layout/Footer";
export default {
  name: "App",
  data: () => ({}),
  components: {
    SuccessMsg,
    errorAler,
    Nav,
    Footer,
  },
  async created() {
    await this.$store.dispatch("lastNews");
    await this.$store.dispatch("getBranches");
  },
  mounted() {
    document.getElementById("loading-bg").style.opacity = "0";
    setTimeout(() => {
      document.getElementById("app").style.opacity = "1";
    });
    document.getElementById("loading-bg").remove();
  },
  computed: {
    successMsg() {
      return this.$store.getters.successMsg;
    },
    errorMsg() {
      return this.$store.getters.errorMsg;
    },
  },
};
</script>
