<template>
	<div v-if="loaded">
		<Subscribe />
		<div class="bg-footerBg w-full pb-12 pt-12 lg:pt-48">
			<div class="container mx-auto px-5 lg:px-8">
				<img
					@click="$router.push('/')"
					src="../../assets/imgs/libyan_labs_logo_dark_bg_withtext.png"
					height="80px"
					width="80px"
					class="mx-auto cursor-pointer object-cover"
					alt=""
					loading="lazy"
				/>
				<ul
					class="flex items-center justify-center flex-wrap md:flex-nowrap md:justify-around text-links tracking-wide w-3/4 mx-auto text-sm my-12"
				>
					<router-link :to="{ name: 'Home' }" class="mx-3 text-links w-full md:w-auto text-center my-2">
						{{ $t('navbar.home') }}
					</router-link>
					<router-link :to="{ name: 'About' }" class="mx-3 text-links w-full md:w-auto text-center my-2">
						{{ $t('navbar.about') }}
					</router-link>
					<router-link :to="{ name: 'Services' }" class="mx-3 text-links w-full md:w-auto text-center my-2">
						{{ $t('navbar.services') }}
					</router-link>
					<router-link :to="{ name: 'News' }" class="mx-3 text-links w-full md:w-auto text-center my-2">
						{{ $t('navbar.news') }}
					</router-link>
					<router-link :to="{ name: 'Structure' }" class="mx-3 text-links w-full md:w-auto text-center my-2">
						{{ $t('navbar.organizationalStructure') }}
					</router-link>
					<router-link :to="{ name: 'Branches' }" class="mx-3 text-links w-full md:w-auto text-center my-2">
						{{ $t('navbar.Branches') }}
					</router-link>
					<router-link :to="{ name: 'Events' }" class="mx-3 text-links w-full md:w-auto text-center my-2">
						{{ $t('navbar.events') }}
					</router-link>
					<router-link :to="{ name: 'Contact' }" class="mx-3 text-links w-full md:w-auto text-center my-2">
						{{ $t('navbar.contact') }}
					</router-link>
				</ul>
				<span class="bg-dateText block w-full rounded-lg" style="height: 2px"></span>
				<div class="flex items-center justify-center md:justify-between flex-wrap mt-5">
					<div
						class="text-white w-full font-medium md:w-1/3 text-justify flex items-center justify-between md:justify-start my-3"
					>
						<a rel="noreferrer" href="tel:0945304779">0945304779</a>
						<a rel="noreferrer" class="md:mx-5" href="mailto:info@llms.ly">info@llms.ly</a>
					</div>
					<div class="text-links text-center w-full md:w-1/3 my-3">
						&copy; {{ $t('copyright') + ' ' + $moment().format('YYYY') }}
					</div>
					<div class="w-full md:w-1/3 my-3">
						<a rel="noreferrer" href="https://web.facebook.com/gm.llms" target="_blank"
							><img
								:class="$i18n.locale == 'ar' ? 'mx-auto md:float-left' : 'mx-auto md:float-right'"
								src="../../assets/facebook.svg"
								alt=""
								class="object-cover"
								loading="lazy"
						/></a>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import Subscribe from './Subscribe';
export default {
	name: 'Footer',
	data() {
		return { loaded: false };
	},
	components: {
		Subscribe,
	},
	mounted() {
		this.loaded = true;
	},
};
</script>
