import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';

import vSelect from 'vue-select';
import Vuelidate from 'vuelidate';
import { Datetime } from 'vue-datetime';
import i18n from './i18n';
import moment from 'moment';
import AOS from 'aos';
import mapboxgl from 'mapbox-gl';
import axios from './axios';
import VueAwesomeSwiper from 'vue-awesome-swiper'

// import style (<= Swiper 5.x)
import 'swiper/css/swiper.css'
import 'vue-select/dist/vue-select.css';
import 'vue-datetime/dist/vue-datetime.css';
import 'mapbox-gl/dist/mapbox-gl.css';
import 'aos/dist/aos.css';
import './index.css';

Vue.use(Datetime);
Vue.use(Vuelidate);

Vue.component('v-select', vSelect);
Vue.component('datetime', Datetime);
Vue.use(VueAwesomeSwiper, /* { default options with global component } */ )
Vue.prototype.$moment = moment;
Vue.prototype.$axios = axios;

AOS.init();

mapboxgl.accessToken = 'pk.eyJ1IjoiZmFyYWpzaHVhaWIiLCJhIjoiY2tuMjFoeW1wMHJyODJwcGJscTllb29hMSJ9.bzjQzcDqVg9yG2fMehyLhw';
mapboxgl.setRTLTextPlugin(
    'https://api.mapbox.com/mapbox-gl-js/plugins/mapbox-gl-rtl-text/v0.2.3/mapbox-gl-rtl-text.js',
    null,
    true // Lazy load the plugin
);

new Vue({
    router,
    store,
    i18n,
    render: (h) => h(App),
}).$mount('#app');