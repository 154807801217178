import axios from "@/axios";

const getData = {
  state: () => ({
    branches: [],
    lastNews: null,
    News: [],
    newsDetails: null,
    upcomingEvents: null,
    outgoingEvents: null,
    Structure: [],
  }),
  mutations: {
    getBranches: (state, payload) => {
      state.branches = payload;
    },
    lastNews: (state, payload) => {
      state.lastNews = payload;
    },
    News: (state, payload) => {
      state.News = payload;
    },
    newsDetails: (state, payload) => {
      state.newsDetails = payload;
    },
    upcomingEvents: (state, payload) => {
      state.upcomingEvents = payload;
    },
    outgoingEvents: (state, payload) => {
      state.outgoingEvents = payload;
    },
    fetchStructureData: (state, payload) => {
      state.Structure = payload;
    },
  },
  actions: {
    getBranches({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .get("/api/branches")
          .then(({ data }) => {
            commit("getBranches", data.data);
            resolve(data.data);
          })
          .catch((err) => {
            reject(err.response);
          });
      });
    },
    News({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .get("/api/news")
          .then(({ data }) => {
            commit("News", data.data);
            resolve(data.data);
          })
          .catch((err) => reject(err.response));
      });
    },
    lastNews({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .get("/api/lastNews")
          .then(({ data }) => {
            commit("lastNews", data.data);
            resolve(data.data);
          })
          .catch((err) => reject(err.response));
      });
    },
    fetchNewsDetails({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/news/${payload}`)
          .then(({ data }) => {
            commit("newsDetails", data.data);
            resolve(data.data);
          })
          .catch((err) => reject(err.response));
      });
    },
    fetchUpcomingEvents({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .get("/api/upcomingEvents")
          .then(({ data }) => {
            commit("upcomingEvents", data.data);
            resolve(data.data);
          })
          .catch((err) => reject(err.response));
      });
    },
    fetchOutgoingEvents({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .get("/api/outgoingEvents")
          .then(({ data }) => {
            commit("outgoingEvents", data.data);
            resolve(data.data);
          })
          .catch((err) => reject(err.response));
      });
    },
    fetchEventDetails(_, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/events/${payload}`)
          .then(({ data }) => {
            resolve(data);
          })
          .catch((err) => reject(err.response));
      });
    },
    participant(_, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post("/api/participant", payload)
          .then((res) => resolve(res))
          .catch((err) => reject(err.response));
      });
    },
    fetchStructureData({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .get("/api/structure")
          .then(({ data }) => {
            resolve(data.data);
            commit("fetchStructureData", data.data);
          })
          .catch((err) => reject(err.response));
      });
    },
  },
  getters: {
    getBranches(state) {
      return state.branches;
    },
    lastNews(state) {
      return state.lastNews;
    },
    News(state) {
      return state.News;
    },
    newsDetails(state) {
      return state.newsDetails;
    },
    upcomingEvents(state) {
      return state.upcomingEvents;
    },
    outgoingEvents(state) {
      return state.outgoingEvents;
    },
    Structure(state) {
      return state.Structure;
    },
  },
};

export default getData;
