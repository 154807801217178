<template>
  <transition name="slideTop">
    <div
      v-if="animate"
      class="flex justify-center items-center m-1 font-medium py-1 px-2 rounded-md text-green-100 bg-red-600 border border-red-700"
    >
      <div>
        <i class="bx bxs-error-circle text-3xl mx-3 text-white"></i>
      </div>
      <div class="text-xl font-normal text-right">
        <h1 class="py-2 font-medium">
          خطأ!
          <p v-for="err in errorMsg" :key="err" class="text-sm font-base">
            {{ err }}
          </p>
        </h1>
      </div>
      <div class="flex flex-auto">
        <button
          @click="toggle"
          class="bg-white text-red-600 focus:outline-none font-medium mx-3 text-lg rounded-md px-3 py-1"
        >
          حسناً
        </button>
      </div>
    </div>
  </transition>
</template>
<script>
export default {
  data() {
    return {
      animate: false,
    };
  },
  mounted() {
    setTimeout(() => {
      this.animate = true;
    }, 10);
    document.addEventListener("keyup", (evt) => {
      if (evt.keyCode === 27) {
        this.toggle();
      }
    });
  },
  methods: {
    toggle() {
      this.$store.commit("toggleErrorMsg", null);
    },
  },
  computed: {
    errorMsg() {
      return this.$store.getters.errorMsg;
    },
  },
};
</script>