<template>
  <div class="w-full h-full">
    <Header
      :title="$t('Home.services.lab.title')"
      :description="$t('Home.services.lab.content')"
    />
    <div class="w-full h-full flex flex-wrap mt-5">
      <div
        class="
          releative
          border
          w-full
          lg:w-3/4
          mx-3
          lg:mx-auto
          mt-8
          border-formBorderColor
          rounded-md
          p-2
          md:p-5
          flex flex-wrap
        "
      >
        <span class="absolute -mt-8 bg-white text-primary text-lg font-bold">
          {{ $t("registration.basic_information") }}
        </span>
        <div
          v-if="Branches"
          class="p-5 flex items-center justify-center w-full md:w-1/2 flex-wrap"
        >
          <label
            for="branch_id"
            class="font-medium text-lg w-full text-right flex items-center"
            ><span class="text-red-600 mx-2 text-3xl mt-2">*</span>
            {{ $t("registration.lab.branch") }}</label
          >
          <v-select
            v-if="Branches"
            :options="Branches"
            :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
            :label="$i18n.locale == 'ar' ? 'name_ar' : 'name_en'"
            id="branch_id"
            @blur="$v.form.branch_id.$touch()"
            name="branch_id"
            v-model="form.branch_id"
            :reduce="(branch) => branch.id"
            :class="
              defualtInput +
              ($v.form.branch_id.$error
                ? ' border-red-700'
                : 'border-inputBorder')
            "
            class="p-0"
          ></v-select>
          <p
            v-if="$v.form.branch_id.$error"
            class="text-red-600 text-sm my-1 w-full"
          >
            {{ $t("registration.reqMsg") }}
          </p>
        </div>
        <div
          class="p-5 flex items-center justify-center w-full md:w-1/2 flex-wrap"
        >
          <label
            for="lab_name"
            class="font-medium text-lg w-full text-right flex items-center"
          >
            <span class="text-red-600 mx-2 text-3xl mt-2">*</span>
            {{ $t("registration.lab.lab_name") }}
          </label>
          <input
            id="lab_name"
            name="lab_name"
            v-model="form.lab_name"
            type="text"
            required
            @blur="$v.form.lab_name.$touch()"
            :class="
              defualtInput +
              ($v.form.lab_name.$error
                ? ' border-red-700'
                : 'border-inputBorder')
            "
          />
          <p
            v-if="$v.form.lab_name.$error"
            class="text-red-600 text-sm my-1 w-full"
          >
            {{ $t("registration.reqMsg") }}
          </p>
        </div>
        <div
          class="p-5 flex items-center justify-center w-full md:w-1/2 flex-wrap"
        >
          <label
            for="chamber_of_commerce_registration_number"
            class="font-medium text-lg w-full text-right flex items-center"
          >
            {{ $t("registration.lab.Chamber_of_Commerce_registration_number") }}
          </label>
          <input
            id="chamber_of_commerce_registration_number"
            name="chamber_of_commerce_registration_number"
            v-model="form.chamber_of_commerce_registration_number"
            type="text"
            required
            :class="defualtInput + 'border-inputBorder'"
          />
        </div>

        <div
          class="p-5 flex items-center justify-center w-full md:w-1/2 flex-wrap"
        >
          <label
            for="email"
            class="font-medium text-lg w-full text-right flex items-center"
          >
            {{ $t("registration.lab.email") }}
          </label>
          <input
            id="email"
            name="email"
            v-model="form.email"
            type="text"
            required
            :class="defualtInput + 'border-inputBorder'"
          />
        </div>
        <div
          class="p-5 flex items-center justify-center w-full md:w-1/2 flex-wrap"
        >
          <label
            for="website"
            class="font-medium text-lg w-full text-right flex items-center"
          >
            {{ $t("registration.lab.website") }}
          </label>
          <input
            id="website"
            name="website"
            v-model="form.website"
            type="text"
            required
            :class="defualtInput + 'border-inputBorder'"
          />
        </div>
      </div>
      <div
        class="
          releative
          border
          w-full
          lg:w-3/4
          mx-3
          lg:mx-auto
          mt-8
          border-formBorderColor
          rounded-md
          p-5
          flex flex-wrap
        "
      >
        <span class="absolute -mt-8 bg-white text-primary text-lg font-bold">
          {{ $t("registration.License_data") }}
        </span>
        <div
          class="p-5 flex items-center justify-center w-full md:w-1/2 flex-wrap"
        >
          <label
            for="license_number"
            class="font-medium text-lg w-full text-right flex items-center"
          >
            {{ $t("registration.lab.license_number") }}
          </label>
          <input
            id="license_number"
            name="license_number"
            v-model="form.license_number"
            type="text"
            required
            :class="defualtInput + 'border-inputBorder'"
          />
        </div>
        <div
          class="p-5 flex items-center justify-center w-full md:w-1/2 flex-wrap"
        >
          <label
            for="Where_license_issued"
            class="
              font-medium
              text-lg
              w-full
              text-right text-h1Text
              flex
              items-center
            "
          >
            {{ $t("registration.lab.Where_license_issued") }}</label
          >
          <input
            id="Where_license_issued"
            name="Where_license_issued"
            v-model="form.Where_license_issued"
            type="text"
            required
            :class="defualtInput + 'border-inputBorder'"
          />
        </div>
        <div
          class="p-5 flex items-center justify-center w-full md:w-1/2 flex-wrap"
        >
          <label
            for="date_of_license_issue"
            class="font-medium text-lg w-full text-right flex items-center"
            >{{ $t("registration.lab.date") }}</label
          >
          <datetime
            id="date_of_license_issue"
            name="date_of_license_issue"
            v-model="form.date_of_license_issue"
            format="yyyy-MM-dd"
            :max-datetime="maxDate"
            :class="defualtInput + 'border-inputBorder'"
            type="text"
          >
          </datetime>
        </div>
      </div>
      <div
        class="
          releative
          border
          w-full
          lg:w-3/4
          mx-3
          lg:mx-auto
          mt-8
          border-formBorderColor
          rounded-md
          p-2
          md:p-5
          flex flex-wrap
        "
      >
        <span class="absolute -mt-8 bg-white text-primary text-lg font-bold">
          {{ $t("registration.Practicing_permission_information") }}
        </span>
        <div
          class="p-5 flex items-center justify-center w-full md:w-1/2 flex-wrap"
        >
          <label
            for="Service_practice_number"
            class="font-medium text-lg w-full text-right flex items-center"
          >
            {{ $t("registration.lab.Service_practice_number") }}
          </label>
          <input
            id="Service_practice_number"
            name="Service_practice_number"
            v-model="form.Service_practice_number"
            type="text"
            required
            :class="defualtInput + 'border-inputBorder'"
          />
        </div>
        <div
          class="p-5 flex items-center justify-center w-full md:w-1/2 flex-wrap"
        >
          <label
            for="where_service_practice_issued"
            class="
              font-medium
              text-lg
              w-full
              text-right text-h1Text
              flex
              items-center
            "
            >{{ $t("registration.lab.where_service_practice_issued") }}</label
          >
          <input
            id="where_service_practice_issued"
            name="where_service_practice_issued"
            v-model="form.where_service_practice_issued"
            type="text"
            required
            :class="defualtInput + 'border-inputBorder'"
          />
        </div>
        <div
          class="p-5 flex items-center justify-center w-full md:w-1/2 flex-wrap"
        >
          <label
            for="date_of_service_practice_issue"
            class="font-medium text-lg w-full text-right flex items-center"
          >
            {{ $t("registration.lab.date") }}</label
          >
          <datetime
            id="date_of_service_practice_issue"
            name="date_of_service_practice_issue"
            v-model="form.date_of_service_practice_issue"
            format="yyyy-MM-dd"
            :max-datetime="maxDate"
            :class="defualtInput + 'border-inputBorder'"
            type="text"
          >
          </datetime>
        </div>
      </div>
      <div
        class="
          releative
          border
          w-full
          lg:w-3/4
          mx-3
          lg:mx-auto
          mt-8
          border-formBorderColor
          rounded-md
          p-2
          md:p-5
          flex flex-wrap
        "
      >
        <span class="absolute -mt-8 bg-white text-primary text-lg font-bold">
          {{ $t("registration.personal_info") }}
        </span>

        <div
          class="p-5 flex items-center justify-center w-full md:w-1/2 flex-wrap"
        >
          <label
            for="lap_owner_name"
            class="
              font-medium
              text-lg
              w-full
              text-right text-h1Text
              flex
              items-center
            "
            ><span class="text-red-600 mx-2 text-3xl mt-2">*</span>
            {{ $t("registration.lab.lap_owner_name") }}</label
          >
          <input
            id="lap_owner_name"
            name="lap_owner_name"
            v-model="form.lap_owner_name"
            type="text"
            required
            @blur="$v.form.lap_owner_name.$touch()"
            :class="
              defualtInput +
              ($v.form.lap_owner_name.$error
                ? ' border-red-700'
                : 'border-inputBorder')
            "
          />
          <p
            v-if="$v.form.lap_owner_name.$error"
            class="text-red-600 text-sm my-1 w-full"
          >
            {{ $t("registration.reqMsg") }}
          </p>
        </div>
        <div
          class="p-5 flex items-center justify-center w-full md:w-1/2 flex-wrap"
        >
          <label
            for="qualification"
            class="font-medium text-lg w-full text-right flex items-center"
            ><span class="text-red-600 mx-2 text-3xl mt-2">*</span>
            {{ $t("registration.lab.qualification") }}</label
          >
          <input
            id="qualification"
            name="qualification"
            v-model="form.qualification"
            type="text"
            required
            @blur="$v.form.qualification.$touch()"
            :class="
              defualtInput +
              ($v.form.qualification.$error
                ? ' border-red-700'
                : 'border-inputBorder')
            "
          />
          <p
            v-if="$v.form.qualification.$error"
            class="text-red-600 text-sm my-1 w-full"
          >
            {{ $t("registration.reqMsg") }}
          </p>
        </div>
        <div
          class="p-5 flex items-center justify-center w-full md:w-1/2 flex-wrap"
        >
          <label
            for="owner_phone_number"
            class="
              font-medium
              text-lg
              w-full
              text-right text-h1Text
              flex
              items-center
            "
            ><span class="text-red-600 mx-2 text-3xl mt-2">*</span>
            {{ $t("registration.lab.phone_number") }}</label
          >
          <input
            id="owner_phone_number"
            name="owner_phone_number"
            v-model="form.owner_phone_number"
            type="number"
            required
            @blur="$v.form.owner_phone_number.$touch()"
            :class="
              defualtInput +
              ($v.form.owner_phone_number.$error
                ? ' border-red-700'
                : 'border-inputBorder')
            "
          />
          <p
            v-if="
              $v.form.owner_phone_number.$error &&
              !$v.form.owner_phone_number.required
            "
            class="text-red-600 text-sm my-1 w-full"
          >
            {{ $t("registration.reqMsg") }}
          </p>
          <p
            v-if="!$v.form.owner_phone_number.minLength"
            class="text-red-600 text-sm my-1 w-full"
          >
            {{ $t("registration.phone_format") }}
          </p>
        </div>
        <div
          class="p-5 flex items-center justify-center w-full md:w-1/2 flex-wrap"
        >
          <label
            for="owner_email"
            class="
              font-medium
              text-lg
              w-full
              text-right text-h1Text
              flex
              items-center
            "
            >{{ $t("registration.lab.email") }}</label
          >
          <input
            id="owner_email"
            name="owner_email"
            v-model="form.owner_email"
            type="email"
            required
            :class="defualtInput + 'border-inputBorder'"
          />
        </div>
      </div>
      <div
        class="
          releative
          border
          w-full
          lg:w-3/4
          mx-3
          lg:mx-auto
          mt-8
          border-formBorderColor
          rounded-md
          p-5
          flex flex-wrap
        "
      >
        <span class="absolute -mt-8 bg-white text-primary text-lg font-bold">
          {{ $t("registration.lab.Lab_address") }}
        </span>
        <div
          class="p-5 flex items-center justify-center w-full md:w-1/2 flex-wrap"
        >
          <label
            for="city"
            class="
              font-medium
              text-lg
              w-full
              text-right text-h1Text
              flex
              items-center
            "
            ><span class="text-red-600 mx-2 text-3xl mt-2">*</span
            >{{ $t("registration.lab.city") }}</label
          >
          <input
            id="city"
            name="city"
            v-model="form.city"
            type="address"
            required
            @blur="$v.form.city.$touch()"
            :class="
              defualtInput +
              ($v.form.city.$error ? ' border-red-700' : 'border-inputBorder')
            "
          />
          <p
            v-if="$v.form.city.$error"
            class="text-red-600 text-sm my-1 w-full"
          >
            {{ $t("registration.reqMsg") }}
          </p>
        </div>

        <div
          class="p-5 flex items-center justify-center w-full md:w-1/2 flex-wrap"
        >
          <label
            for="region"
            class="
              font-medium
              text-lg
              w-full
              text-right text-h1Text
              flex
              items-center
            "
            ><span class="text-red-600 mx-2 text-3xl mt-2">*</span>
            {{ $t("registration.lab.region") }}</label
          >
          <input
            id="region"
            name="region"
            v-model="form.region"
            type="address"
            required
            @blur="$v.form.region.$touch()"
            :class="
              defualtInput +
              ($v.form.region.$error ? ' border-red-700' : 'border-inputBorder')
            "
          />
          <p
            v-if="$v.form.region.$error"
            class="text-red-600 text-sm my-1 w-full"
          >
            {{ $t("registration.reqMsg") }}
          </p>
        </div>

        <div
          class="p-5 flex items-center justify-center w-full md:w-1/2 flex-wrap"
        >
          <label
            for="street"
            class="
              font-medium
              text-lg
              w-full
              text-right text-h1Text
              flex
              items-center
            "
            ><span class="text-red-600 mx-2 text-3xl mt-2">*</span>
            {{ $t("registration.lab.street") }}</label
          >
          <input
            id="street"
            name="street"
            v-model="form.street"
            type="address"
            required
            @blur="$v.form.street.$touch()"
            :class="
              defualtInput +
              ($v.form.street.$error ? ' border-red-700' : 'border-inputBorder')
            "
          />
          <p
            v-if="$v.form.street.$error"
            class="text-red-600 text-sm my-1 w-full"
          >
            {{ $t("registration.lab.reqMsg") }}
          </p>
        </div>
      </div>
      <div
        class="
          releative
          border
          w-full
          lg:w-3/4
          mx-3
          lg:mx-auto
          mt-8
          border-formBorderColor
          rounded-md
          p-5
          flex flex-wrap
        "
      >
        <span class="absolute -mt-8 bg-white text-primary text-lg font-bold">
          {{ $t("registration.more_data") }}
        </span>
        <div
          class="
            p-5
            flex
            items-center
            justify-center
            w-full
            lg:w-3/4
            mx-auto
            flex-wrap
          "
        >
          <label
            for="notes"
            class="font-medium text-h1Text text-lg w-full text-right"
            >{{ $t("registration.note") }}</label
          >
          <textarea
            v-model="form.notes"
            class="form-textarea"
            :class="defualtInput"
          >
          </textarea>
        </div>
      </div>
    </div>
    <div class="flex w-full lg:w-3/4 mx-auto items-center justify-end">
      <p v-if="$v.form.$error" class="text-red-600 text-md font-medium">
        {{ $t("registration.completeReqMsg") }}
      </p>
      <button
        @click="$router.go(-1)"
        class="
          px-8
          rounded-md
          w-auto
          shadow
          py-2
          focus:outline-none
          hover:shadow-lg
          m-3
          md:m-5
          border border-primary
          text-primary
          bg-white
          text-lg
          font-medium
        "
      >
        {{ $t("registration.back") }}
      </button>
      <button
        :disabled="$v.form.$error"
        @click.prevent="submit"
        class="
          px-8
          rounded-md
          w-auto
          flex
          items-center
          shadow
          py-2
          focus:outline-none
          hover:shadow-lg
          m-3
          md:m-5
          bg-primary
          text-white text-lg
          font-medium
        "
      >
        <span>{{ $t("registration.submit") }} </span
        ><i
          v-if="loading"
          class="text-2xl mx-2 bx bx-loader-circle bx-spin"
        ></i>
      </button>
    </div>
  </div>
</template>
<script>
import {
  required,
  email,
  numeric,
  url,
  minLength,
} from "vuelidate/lib/validators";
import moment from "moment";
import Header from "@/components/layout/Header";

export default {
  name: "labs",
  data() {
    return {
      defualtInput:
        "p-2  bg-white rounded-md border w-full text-right focus-within:border-primary hover:border-primary focus:border-primary focus:outline-none focus:shadow-md my-1",
      maxDate: moment().toISOString(),
      loading: false,
      form: {
        branch_id: null,
        lab_name: null,
        chamber_of_commerce_registration_number: null,
        email: null,
        website: "",
        license_number: null,
        Where_license_issued: null,
        date_of_license_issue: null,
        Service_practice_number: null,
        where_service_practice_issued: null,
        date_of_service_practice_issue: null,
        lap_owner_name: null,
        qualification: null,
        owner_email: null,
        owner_phone_number: null,
        city: null,
        region: null,
        street: null,
        notes: null,
      },
    };
  },
  computed: {
    Branches() {
      return this.$store.getters.getBranches;
    },
  },
  components: {
    Header,
  },
  mounted() {
    this.$store.dispatch("getBranches");
  },
  created() {
    window.onpopstate = () => {
      if (this.$route.name == "Home" || this.$route.name == "LabsPanel") {
        this.clear();
      }
    };
  },
  validations() {
    return {
      form: {
        branch_id: { required, numeric },
        lab_name: { required },
        email: { email },
        website: { url },
        lap_owner_name: { required },
        qualification: { required },
        owner_phone_number: { required, minLength: minLength(10) },
        city: { required },
        region: { required },
        street: { required },
      },
    };
  },
  methods: {
    submit() {
      this.$v.form.$touch();
      if (this.$v.form.$pending || this.$v.form.$error) return;
      this.loading = true;
      const newFormat = {
        ...this.form,
        date_of_license_issue: moment(this.date_of_license_issue).format(
          "YYYY/MM/DD"
        ),
        date_of_service_practice_issue: moment(
          this.date_of_service_practice_issue
        ).format("YYYY/MM/DD"),
      };
      this.$store
        .dispatch("labsSubmiting", newFormat)
        .then(() => {
          this.loading = false;
          this.$store.commit(
            "toggleSuccessMsg",
            this.$t("registration.successMsg")
          );
        })
        .catch((messages) => {
          this.loading = false;
          this.$store.commit("toggleErrorMsg", messages);
        });
    },
  },
};
</script>
