var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"w-full h-full"},[_c('div',{staticClass:"w-full h-full flex flex-wrap mt-5"},[_c('div',{staticClass:"\n        releative\n        border\n        w-full\n        lg:w-3/4\n        mx-3\n        lg:mx-auto\n        mt-8\n        border-formBorderColor\n        rounded-md\n        p-5\n        flex flex-wrap\n      "},[_c('span',{staticClass:"absolute -mt-8 bg-white text-primary text-lg font-bold"},[_vm._v(" "+_vm._s(_vm.$t("registration.basic_information"))+" ")]),_c('div',{staticClass:"w-full m-5"},[_c('input',{ref:"uploadImg",staticClass:"hidden",attrs:{"type":"file","accept":"image/*"},on:{"change":_vm.OnPickerChange}}),_c('div',{staticClass:"relative h-48 w-40"},[(_vm.imageSrc)?_c('i',{staticClass:"\n              bx\n              bxs-x-circle\n              cursor-pointer\n              rounded-full\n              bg-white\n              text-gray-500 text-3xl\n              absolute\n              left-0\n              -mt-3\n              -ml-2\n            ",on:{"click":function($event){_vm.imageSrc = null;
              _vm.form.doctor_image = null;}}}):_vm._e(),_c('button',{staticClass:"\n              border-4 border-gray-500\n              outline-none\n              focus:outline-none\n              border-dashed\n              rounded-lg\n              lg:rounded-md\n              w-full\n              h-full\n              flex\n              items-center\n              justify-center\n            ",on:{"click":function($event){$event.preventDefault();return _vm.pickPicture.apply(null, arguments)}}},[(!_vm.imageSrc)?_c('div',[_c('i',{staticClass:"bx bxs-user text-gray-500 text-4xl"}),_c('h3',{staticClass:"text-gray-500 text-sm mt-1"},[_vm._v(" "+_vm._s(_vm.$t("registration.individual.profileImg"))+" ")])]):_c('img',{staticClass:"w-full h-full object-cover",attrs:{"src":_vm.imageSrc,"loading":"lazy"}})])])]),_c('div',{staticClass:"p-5 flex items-center justify-center w-full md:w-1/2 flex-wrap"},[_c('label',{staticClass:"font-medium text-lg w-full  flex items-center",attrs:{"for":"name"}},[_c('span',{staticClass:"text-red-600 mx-2"},[_vm._v("*")]),_vm._v(" "+_vm._s(_vm.$t("registration.individual.full_name")))]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.name),expression:"form.name"}],class:_vm.defualtInput +
            (_vm.$v.form.name.$error ? ' border-red-700' : 'border-inputBorder'),attrs:{"id":"name","name":"name","type":"text","required":""},domProps:{"value":(_vm.form.name)},on:{"blur":function($event){return _vm.$v.form.name.$touch()},"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "name", $event.target.value)}}}),(_vm.$v.form.name.$error)?_c('p',{staticClass:"text-red-600 text-sm my-1 w-full"},[_vm._v(" "+_vm._s(_vm.$t("registration.reqMsg"))+" ")]):_vm._e()]),_c('div',{staticClass:"p-5 flex items-center justify-center w-full md:w-1/2 flex-wrap"},[_c('label',{staticClass:"font-medium text-lg w-full  flex items-center",attrs:{"for":"nationalities"}},[_c('span',{staticClass:"text-red-600 mx-2 "},[_vm._v("*")]),_vm._v(_vm._s(_vm.$t("registration.individual.nationality")))]),_c('v-select',{staticClass:"p-0",class:_vm.defualtInput +
            (_vm.$v.form.nationality.$error
              ? ' border-red-700'
              : 'border-inputBorder'),attrs:{"options":_vm.nationalities,"dir":_vm.$i18n.locale == 'ar' ? 'rtl' : 'ltr',"label":"name","id":"nationalities","clearable":false,"name":"nationalities","reduce":function (nationality) { return nationality.value; }},on:{"blur":function($event){return _vm.$v.form.nationality.$touch()}},model:{value:(_vm.form.nationality),callback:function ($$v) {_vm.$set(_vm.form, "nationality", $$v)},expression:"form.nationality"}}),(_vm.$v.form.nationality.$error)?_c('p',{staticClass:"text-red-600 text-sm my-1 w-full"},[_vm._v(" "+_vm._s(_vm.$t("registration.reqMsg"))+" ")]):_vm._e()],1),(_vm.form.nationality == 'Libyan')?_c('div',{staticClass:"p-5 flex items-center justify-center w-full md:w-1/2 flex-wrap"},[_c('label',{staticClass:"font-medium text-lg w-full  flex items-center",attrs:{"for":"id_number"}},[_c('span',{staticClass:"text-red-600 mx-2 "},[_vm._v("*")]),_vm._v(" "+_vm._s(_vm.$t("registration.individual.id"))+" ")]),_c('input',{directives:[{name:"model",rawName:"v-model.number",value:(_vm.form.id_number),expression:"form.id_number",modifiers:{"number":true}}],class:_vm.defualtInput +
            (_vm.$v.form.id_number.$error
              ? ' border-red-700'
              : 'border-inputBorder'),attrs:{"id":"id_number","name":"id_number","type":"number","required":""},domProps:{"value":(_vm.form.id_number)},on:{"blur":[function($event){return _vm.$v.form.id_number.$touch()},function($event){return _vm.$forceUpdate()}],"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "id_number", _vm._n($event.target.value))}}}),(_vm.$v.form.id_number.$error)?_c('p',{staticClass:"text-red-600 text-md font-medium w-full "},[_vm._v(" "+_vm._s(_vm.$t("registration.individual.id_confrim"))+" ")]):_vm._e()]):_vm._e(),(_vm.form.nationality == 'Libyan')?_c('div',{staticClass:"p-5 flex items-center justify-center w-full md:w-1/2 flex-wrap"},[_c('label',{staticClass:"font-medium text-lg w-full  flex items-center",attrs:{"for":"identity_number"}},[_c('span',{staticClass:"text-red-600 mx-2 "},[_vm._v("*")]),_vm._v(" "+_vm._s(_vm.$t("registration.individual.id_or_card"))+" ")]),_c('div',{staticClass:"w-full lg:pl-3 flex flex-wrap"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.identity_number),expression:"form.identity_number"}],class:_vm.defualtInput +
              (_vm.$v.form.identity_number.$error
                ? ' border-red-700'
                : 'border-inputBorder'),attrs:{"id":"identity_number","name":"identity_number","type":"text","required":""},domProps:{"value":(_vm.form.identity_number)},on:{"blur":function($event){return _vm.$v.form.identity_number.$touch()},"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "identity_number", $event.target.value)}}}),_c('div',{staticClass:"w-full flex items-center flex-wrap mt-1"},[_c('label',{staticClass:"inline-flex items-center mx-2"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.type_of_identity),expression:"form.type_of_identity"}],staticClass:"form-radio",class:_vm.$v.form.type_of_identity.$error
                    ? ' border-red-700'
                    : 'border-inputBorder',attrs:{"type":"radio","name":"WorkType","value":"جواز السفر"},domProps:{"checked":_vm._q(_vm.form.type_of_identity,"جواز السفر")},on:{"blur":function($event){return _vm.$v.form.type_of_identity.$touch()},"change":function($event){return _vm.$set(_vm.form, "type_of_identity", "جواز السفر")}}}),_c('span',{staticClass:"mx-2 text-h1Text"},[_vm._v(_vm._s(_vm.$t("registration.individual.passport_no")))])]),_c('label',{staticClass:"inline-flex items-center mx-2"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.type_of_identity),expression:"form.type_of_identity"}],staticClass:"form-radio",class:_vm.$v.form.type_of_identity.$error
                    ? ' border-red-700'
                    : 'border-inputBorder',attrs:{"type":"radio","name":"WorkType","value":"بطاقة شخصية"},domProps:{"checked":_vm._q(_vm.form.type_of_identity,"بطاقة شخصية")},on:{"blur":function($event){return _vm.$v.form.type_of_identity.$touch()},"change":function($event){return _vm.$set(_vm.form, "type_of_identity", "بطاقة شخصية")}}}),_c('span',{staticClass:"mx-2 text-h1Text"},[_vm._v(_vm._s(_vm.$t("registration.individual.card_no")))])])])]),(_vm.$v.form.identity_number.$error)?_c('p',{staticClass:"text-red-600 text-sm my-1 w-full"},[_vm._v(" "+_vm._s(_vm.$t("registration.reqMsg"))+" ")]):_vm._e()]):_vm._e(),(_vm.form.nationality != 'Libyan')?_c('div',{staticClass:"p-5 flex items-center justify-center w-full md:w-1/2 flex-wrap"},[_c('label',{staticClass:"font-medium text-lg w-full  flex items-center",attrs:{"for":"passport_copy"}},[_c('span',{staticClass:"text-red-600 mx-2 "},[_vm._v("*")]),_vm._v(" "+_vm._s(_vm.$t("registration.individual.passport_no_doc"))+" ")]),_c('input',{ref:"passport_copy",staticClass:"hidden",attrs:{"id":"passport_copy","name":"passport_copy","accept":"image/*,.pdf","type":"file","required":""},on:{"change":_vm.passport_copyFunc}}),_c('div',{staticClass:"w-full flex items-center relative"},[_c('button',{staticClass:"\n              bg-primary\n              rounded-r-md\n              focus:outline-none\n              absolute\n              p-2\n              z-20\n              text-white\n            ",on:{"click":_vm.pickpassport_copyFunc}},[_vm._v(" "+_vm._s(_vm.$t("registration.company.choose_file"))+" ")]),_c('input',{staticClass:"pr-20",class:_vm.defualtInput +
              (_vm.$v.form.passport_copy.$error
                ? ' border-red-700'
                : 'border-inputBorder'),attrs:{"type":"text","placeholder":_vm.$t('registration.company.file_not_loaded_yet')},domProps:{"value":_vm.form.passport_copy ? _vm.form.passport_copy.name : null},on:{"click":_vm.pickpassport_copyFunc,"blur":function($event){return _vm.$v.form.passport_copy.$touch()}}})]),(_vm.$v.form.passport_copy.$error)?_c('p',{staticClass:"text-red-600 text-sm my-1 w-full"},[_vm._v(" "+_vm._s(_vm.$t("registration.reqMsg"))+" ")]):_vm._e()]):_vm._e(),(_vm.form.nationality != 'Libyan')?_c('div',{staticClass:"p-5 flex items-center justify-center w-full md:w-1/2 flex-wrap"},[_c('label',{staticClass:"font-medium text-lg w-full  flex items-center",attrs:{"for":"residency_photo"}},[_c('span',{staticClass:"text-red-600 mx-2 "},[_vm._v("*")]),_vm._v(_vm._s(_vm.$t("registration.individual.residency_photo")))]),_c('input',{ref:"residency_photo",staticClass:"hidden",attrs:{"id":"residency_photo","name":"residency_photo","accept":"image/*,.pdf","type":"file","required":""},on:{"change":_vm.residency_photoFunc}}),_c('div',{staticClass:"w-full flex items-center relative"},[_c('button',{staticClass:"\n              bg-primary\n              rounded-r-md\n              focus:outline-none\n              absolute\n              p-2\n              z-20\n              text-white\n            ",on:{"click":_vm.pickresidency_photoFunc}},[_vm._v(" "+_vm._s(_vm.$t("registration.company.choose_file"))+" ")]),_c('input',{staticClass:"pr-20",class:_vm.defualtInput +
              (_vm.$v.form.residency_photo.$error
                ? ' border-red-700'
                : 'border-inputBorder'),attrs:{"type":"text","placeholder":_vm.$t('registration.company.file_not_loaded_yet')},domProps:{"value":_vm.form.residency_photo ? _vm.form.residency_photo.name : null},on:{"click":_vm.pickresidency_photoFunc,"blur":function($event){return _vm.$v.form.residency_photo.$touch()}}})]),(_vm.$v.form.residency_photo.$error)?_c('p',{staticClass:"text-red-600 text-sm my-1 w-full"},[_vm._v(" "+_vm._s(_vm.$t("registration.reqMsg"))+" ")]):_vm._e()]):_vm._e(),(_vm.Branches)?_c('div',{staticClass:"p-5 flex items-center justify-center w-full md:w-1/2 flex-wrap"},[_c('label',{staticClass:"font-medium text-lg w-full  flex items-center",attrs:{"for":"branch_id"}},[_c('span',{staticClass:"text-red-600 mx-2 "},[_vm._v("*")]),_vm._v(" "+_vm._s(_vm.$t("registration.individual.branch"))+" ")]),_c('v-select',{staticClass:"p-0",class:_vm.defualtInput +
            (_vm.$v.form.branch_id.$error
              ? ' border-red-700'
              : 'border-inputBorder'),attrs:{"options":_vm.Branches,"dir":_vm.$i18n.locale == 'ar' ? 'rtl' : 'ltr',"label":_vm.$i18n.locale == 'ar' ? 'name_ar' : 'name_en',"id":"branch_id","clearable":false,"name":"branch_id","reduce":function (branch_id) { return branch_id.id; }},on:{"blur":function($event){return _vm.$v.form.branch_id.$touch()}},model:{value:(_vm.form.branch_id),callback:function ($$v) {_vm.$set(_vm.form, "branch_id", $$v)},expression:"form.branch_id"}}),(_vm.$v.form.branch_id.$error)?_c('p',{staticClass:"text-red-600 text-sm my-1 w-full"},[_vm._v(" "+_vm._s(_vm.$t("registration.reqMsg"))+" ")]):_vm._e()],1):_vm._e(),_c('div',{staticClass:"p-5 flex items-center justify-center w-full md:w-1/2 flex-wrap"},[_c('label',{staticClass:"font-medium text-lg w-full  flex items-center",attrs:{"for":"marital_status"}},[_c('span',{staticClass:"text-red-600 mx-2 "},[_vm._v("*")]),_vm._v(" "+_vm._s(_vm.$t("registration.individual.marital_status"))+" ")]),_c('v-select',{staticClass:"p-0",class:_vm.defualtInput +
            (_vm.$v.form.marital_status.$error
              ? ' border-red-700'
              : 'border-inputBorder'),attrs:{"options":[
            _vm.$t('registration.individual.marital_status_options.Married'),
            _vm.$t('registration.individual.marital_status_options.divorced'),
            _vm.$t('registration.individual.marital_status_options.single'),
            _vm.$t('registration.individual.marital_status_options.Widower') ],"id":"marital_status","dir":_vm.$i18n.locale == 'ar' ? 'rtl' : 'ltr',"clearable":false,"name":"marital_status"},on:{"blur":function($event){return _vm.$v.form.marital_status.$touch()}},model:{value:(_vm.form.marital_status),callback:function ($$v) {_vm.$set(_vm.form, "marital_status", $$v)},expression:"form.marital_status"}}),(_vm.$v.form.marital_status.$error)?_c('p',{staticClass:"text-red-600 text-sm my-1 w-full"},[_vm._v(" "+_vm._s(_vm.$t("registration.individual.confirm_marital_status"))+" ")]):_vm._e()],1),_c('div',{staticClass:"p-5 flex items-center justify-center w-full md:w-1/2 flex-wrap"},[_c('label',{staticClass:"font-medium text-lg w-full flex items-center",attrs:{"for":"birth_date"}},[_c('span',{staticClass:"text-red-600 mx-2 "},[_vm._v("*")]),_vm._v(" "+_vm._s(_vm.$t("registration.individual.birthDate"))+" ")]),_c('datetime',{class:_vm.defualtInput +
            (_vm.$v.form.birth_date.$error
              ? ' border-red-700'
              : 'border-inputBorder'),attrs:{"id":"birth_date","name":"birth_date","format":"yyyy-MM-dd","max-datetime":_vm.maxDate,"type":"text"},on:{"blur":function($event){return _vm.$v.form.birth_date.$touch()}},model:{value:(_vm.form.birth_date),callback:function ($$v) {_vm.$set(_vm.form, "birth_date", $$v)},expression:"form.birth_date"}}),(_vm.$v.form.birth_date.$error)?_c('p',{staticClass:"text-red-600 text-sm my-1 w-full"},[_vm._v(" "+_vm._s(_vm.$t("registration.reqMsg"))+" ")]):_vm._e()],1),_c('div',{staticClass:"p-5 flex items-center justify-center w-full md:w-1/2 flex-wrap"},[_c('label',{staticClass:"font-medium text-lg w-full flex items-center",attrs:{"for":"address"}},[_c('span',{staticClass:"text-red-600 mx-2 "},[_vm._v("*")]),_vm._v(" "+_vm._s(_vm.$t("registration.individual.address"))+" ")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.address),expression:"form.address"}],class:_vm.defualtInput +
            (_vm.$v.form.address.$error
              ? ' border-red-700'
              : 'border-inputBorder'),attrs:{"id":"address","name":"address","type":"text","required":""},domProps:{"value":(_vm.form.address)},on:{"blur":function($event){return _vm.$v.form.address.$touch()},"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "address", $event.target.value)}}}),(_vm.$v.form.address.$error)?_c('p',{staticClass:"text-red-600 text-sm my-1 w-full"},[_vm._v(" "+_vm._s(_vm.$t("registration.reqMsg"))+" ")]):_vm._e()]),_c('div',{staticClass:"p-5 flex items-center justify-center w-full md:w-1/2 flex-wrap"},[_c('label',{staticClass:"font-medium text-lg w-full",attrs:{"for":"email"}},[_vm._v(" "+_vm._s(_vm.$t("registration.individual.email"))+" ")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.email),expression:"form.email"}],class:_vm.defualtInput + 'border-inputBorder',attrs:{"id":"email","name":"email","type":"email","required":""},domProps:{"value":(_vm.form.email)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "email", $event.target.value)}}})]),_c('div',{staticClass:"p-5 flex items-center justify-center w-full md:w-1/2 flex-wrap"},[_c('label',{staticClass:"font-medium text-lg w-full flex items-center",attrs:{"for":"phone_number"}},[_c('span',{staticClass:"text-red-600 mx-2 "},[_vm._v("*")]),_vm._v(" "+_vm._s(_vm.$t("registration.individual.phone_number"))+" ")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.phone_number),expression:"form.phone_number"}],class:_vm.defualtInput +
            (_vm.$v.form.phone_number.$error
              ? ' border-red-700'
              : 'border-inputBorder'),attrs:{"id":"phone_number","name":"phone_number","type":"number","required":""},domProps:{"value":(_vm.form.phone_number)},on:{"blur":function($event){return _vm.$v.form.phone_number.$touch()},"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "phone_number", $event.target.value)}}}),(_vm.$v.form.phone_number.$error && !_vm.$v.form.phone_number.required)?_c('p',{staticClass:"text-red-600 text-md font-medium w-full"},[_vm._v(" "+_vm._s(_vm.$t("registration.reqMsg"))+" ")]):_vm._e(),(!_vm.$v.form.phone_number.minLength)?_c('p',{staticClass:"text-red-600 text-sm my-1 w-full"},[_vm._v(" "+_vm._s(_vm.$t("registration.phone_format"))+" ")]):_vm._e()])])]),_c('div',{staticClass:"flex w-full lg:w-3/4 mx-auto items-center justify-end"},[(_vm.$v.form.$error)?_c('p',{staticClass:"text-red-600 text-md font-medium"},[_vm._v(" "+_vm._s(_vm.$t("registration.completeReqMsg"))+" ")]):_vm._e(),_c('button',{staticClass:"\n        px-8\n        rounded-md\n        w-auto\n        shadow\n        py-2\n        focus:outline-none\n        hover:shadow-lg\n        m-3\n        md:m-5\n        border border-primary\n        text-primary\n        bg-white\n        text-lg\n        font-medium\n      ",on:{"click":function($event){_vm.clear;
        _vm.$router.go(-1);}}},[_vm._v(" "+_vm._s(_vm.$t("registration.back"))+" ")]),_c('button',{staticClass:"\n        px-8\n        rounded-md\n        w-auto\n        shadow\n        py-2\n        focus:outline-none\n        hover:shadow-lg\n        m-3\n        md:m-5\n        bg-primary\n        text-white text-lg\n        font-medium\n      ",attrs:{"disabled":_vm.$v.form.$error},on:{"click":function($event){$event.preventDefault();return _vm.next.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.$t("registration.next"))+" ")])])])}
var staticRenderFns = []

export { render, staticRenderFns }