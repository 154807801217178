<template>
	<div class="w-full h-full">
		<Header :title="$t('Home.services.company.title')" :description="$t('Home.services.company.content')" />

		<router-view />
	</div>
</template>
<script>
import Header from '@/components/layout/Header';

export default {
	name: 'company',
	created() {
		this.$store.dispatch('getBranches');
	},
	components: {
		Header,
	},
};
</script>
