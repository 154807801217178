<template>
  <div class="w-full h-full">
    <div class="w-full h-full flex flex-row flex-wrap mt-5">
      <div
        class="
          releative
          border
          w-full
          lg:w-3/4
          mx-3
          lg:mx-auto
          mt-8
          border-formBorderColor
          rounded-md
          p-5
          flex flex-wrap
        "
      >
        <span class="absolute -mt-8 bg-white text-primary text-lg font-bold">
          {{ $t("registration.individual.experince") }}
        </span>
        <div
          class="p-5 flex items-center justify-center w-full md:w-1/2 flex-wrap"
        >
          <label
            for="qualification"
            class="font-medium text-lg w-full  flex items-center"
            ><span class="text-red-600 mx-2 text-3xl mt-2">*</span>
            {{ $t("registration.individual.qualification") }}
          </label>
          <input
            id="qualification"
            name="qualification"
            v-model="form.qualification"
            type="text"
            required
            @blur="$v.form.qualification.$touch()"
            :class="
              defualtInput +
              ($v.form.qualification.$error
                ? ' border-red-700'
                : 'border-inputBorder')
            "
          />
          <p
            v-if="$v.form.qualification.$error"
            class="text-red-600 text-sm my-1 w-full"
          >
            {{ $t("registration.reqMsg") }}
          </p>
        </div>
        <div
          class="p-5 flex items-center justify-center w-full md:w-1/2 flex-wrap"
        >
          <label
            for="specialization"
            class="font-medium text-lg w-full  flex items-center"
            ><span class="text-red-600 mx-2 text-3xl mt-2">*</span>
            {{ $t("registration.individual.specialization") }}
          </label>
          <input
            id="specialization"
            name="specialization"
            v-model="form.specialization"
            type="text"
            required
            @blur="$v.form.specialization.$touch()"
            :class="
              defualtInput +
              ($v.form.specialization.$error
                ? ' border-red-700'
                : 'border-inputBorder')
            "
          />
          <p
            v-if="$v.form.specialization.$error"
            class="text-red-600 text-sm my-1 w-full"
          >
            {{ $t("registration.reqMsg") }}
          </p>
        </div>
        <div
          class="p-5 flex items-center justify-center w-full md:w-1/2 flex-wrap"
        >
          <label
            for="year_of_graduation"
            class="font-medium text-lg w-full  flex items-center"
            ><span class="text-red-600 mx-2 text-3xl mt-2">*</span>
            {{ $t("registration.individual.year_of_graduation") }}
          </label>
          <datetime
            id="year_of_graduation"
            name="year_of_graduation"
            v-model="form.year_of_graduation"
            format="yyyy-MM-dd"
            :max-datetime="maxDate"
            @blur="$v.form.year_of_graduation.$touch()"
            :class="
              defualtInput +
              ($v.form.year_of_graduation.$error
                ? ' border-red-700'
                : 'border-inputBorder')
            "
            type="text"
          >
          </datetime>
          <p
            v-if="$v.form.year_of_graduation.$error"
            class="text-red-600 text-sm my-1 w-full"
          >
            {{ $t("registration.reqMsg") }}
          </p>
        </div>
        <div
          class="p-5 flex items-center justify-center w-full md:w-1/2 flex-wrap"
        >
          <label
            for="name_of_the_university"
            class="font-medium text-lg w-full  flex items-center"
            ><span class="text-red-600 mx-2 text-3xl mt-2">*</span>
            {{ $t("registration.individual.name_of_the_university") }}
          </label>
          <input
            id="name_of_the_university"
            name="name_of_the_university"
            v-model="form.name_of_the_university"
            type="text"
            required
            @blur="$v.form.name_of_the_university.$touch()"
            :class="
              defualtInput +
              ($v.form.name_of_the_university.$error
                ? ' border-red-700'
                : 'border-inputBorder')
            "
          />
          <p
            v-if="$v.form.name_of_the_university.$error"
            class="text-red-600 text-sm my-1 w-full"
          >
            {{ $t("registration.reqMsg") }}
          </p>
        </div>
        <div
          class="p-5 flex items-center justify-center w-full md:w-1/2 flex-wrap"
        >
          <label
            for="experience"
            class="font-medium text-lg text-h1Text w-full"
          >
            {{ $t("registration.individual.experince") }}
          </label>
          <input
            id="experience"
            name="experience"
            v-model="form.experience"
            type="text"
            required
            :class="defualtInput + 'border-inputBorder'"
          />
        </div>
        <div
          class="p-5 flex items-center justify-center w-full md:w-1/2 flex-wrap"
        >
          <label
            for="date_of_hiring"
            class="font-medium text-lg text-h1Text w-full"
          >
            {{ $t("registration.individual.date_of_hiring") }}
          </label>
          <datetime
            v-model="form.date_of_hiring"
            format="yyyy-MM-dd"
            :max-datetime="maxDate"
            :class="defualtInput + 'border-inputBorder'"
            type="text"
          >
          </datetime>
        </div>
        <div
          class="p-5 flex items-center justify-center w-full md:w-1/2 flex-wrap"
        >
          <label
            for="current_place_of_work"
            class="font-medium text-lg text-h1Text w-full"
            >{{ $t("registration.individual.current_place_of_work") }}</label
          >
          <div class="w-full lg:pl-3 flex flex-wrap">
            <input
              id="current_place_of_work"
              name="current_place_of_work"
              v-model="form.current_place_of_work"
              type="text"
              required
              :class="defualtInput + 'border-inputBorder'"
            />
            <div class="w-full flex items-center flex-wrap mt-1">
              <label class="inline-flex items-center mx-2">
                <input
                  type="radio"
                  class="form-radio border-inputBorder"
                  name="WorkType"
                  value="public"
                  v-model="form.sector"
                />
                <span class="mx-2 text-h1Text">عام</span>
              </label>
              <label class="inline-flex items-center mx-2">
                <input
                  type="radio"
                  class="form-radio border-inputBorder"
                  name="WorkType"
                  value="private"
                  v-model="form.sector"
                />
                <span class="mx-2 text-h1Text">خاص</span>
              </label>
              <span class="text-sm text-red-500" v-if="$v.form.sector.$error">{{
                $t("registration.individual.sector_req")
              }}</span>
            </div>
          </div>
        </div>

        <div
          class="p-5 flex items-center justify-center w-full md:w-1/2 flex-wrap"
        >
          <label
            for="date_of_joining"
            class="font-medium text-lg text-h1Text w-full"
          >
            {{ $t("registration.individual.date_of_joining") }}
          </label>
          <datetime
            v-model="form.date_of_joining"
            format="yyyy-MM-dd"
            :max-datetime="maxDate"
            :class="defualtInput + 'border-inputBorder'"
            type="text"
          >
          </datetime>
        </div>
        <div
          class="p-5 flex items-center justify-center w-full md:w-1/2 flex-wrap"
        >
          <label for="notes" class="font-medium text-h1Text text-lg w-full"
            >{{ $t("registration.more_data") }}
          </label>
          <textarea v-model="form.notes" :class="defualtInput"> </textarea>
        </div>
      </div>
    </div>
    <div class="flex w-full lg:w-3/4 mx-auto items-center justify-end">
      <p v-if="$v.form.$error" class="text-red-600 text-md font-medium">
        {{ $t("registration.completeReqMsg") }}
      </p>
      <button
        @click="$router.go(-1)"
        class="
          px-8
          rounded-md
          w-auto
          shadow
          py-2
          focus:outline-none
          hover:shadow-lg
          m-3
          md:m-5
          border border-primary
          text-primary
          bg-white
          text-lg
          font-medium
        "
      >
        {{ $t("registration.back") }}
      </button>
      <button
        @click.prevent="submit"
        class="
          px-8
          rounded-md
          w-auto
          flex
          items-center
          shadow
          py-2
          focus:outline-none
          hover:shadow-lg
          m-3
          md:m-5
          bg-primary
          text-white text-lg
          font-medium
        "
      >
        <span>{{ $t("registration.submit") }} </span
        ><i
          v-if="loading"
          class="text-2xl mx-2 bx bx-loader-circle bx-spin"
        ></i>
      </button>
    </div>
  </div>
</template>
<script>
import moment from "moment";
import { required, requiredIf } from "vuelidate/lib/validators";
export default {
  data() {
    return {
      defualtInput:
        "p-2  bg-white rounded-md border w-full  focus-within:border-primary hover:border-primary focus:border-primary focus:outline-none focus:shadow-md my-1",
      maxDate: moment().toISOString(),
      loading: false,
      form: {
        qualification: "",
        specialization: "",
        year_of_graduation: "",
        name_of_the_university: "",
        experience: "",
        date_of_hiring: "",
        current_place_of_work: "",
        sector: "",
        date_of_joining: "",
        notes: "",
      },
    };
  },
  validations() {
    return {
      form: {
        qualification: { required },
        specialization: { required },
        year_of_graduation: { required },
        name_of_the_university: { required },
        sector: {
          required: requiredIf(() => this.form.current_place_of_work),
        },
      },
    };
  },
  computed: {
    editDoctor() {
      return this.$store.getters.editDoctor;
    },
    individualData() {
      return this.$store.getters.individualData;
    },
  },
  created() {
    if (this.individualData) {
      this.form = this.individualData;
    }
  },
  mounted() {
    if (this.editDoctor) {
      this.form = {
        qualification: this.editDoctor.qualification,
        specialization: this.editDoctor.specialization,
        year_of_graduation: this.editDoctor.year_of_graduation,
        name_of_the_university: this.editDoctor.name_of_the_university,
        experience: this.editDoctor.experience,
        date_of_hiring: this.editDoctor.date_of_hiring,
        current_place_of_work: this.editDoctor.current_place_of_work,
        sector: this.editDoctor.sector,
        date_of_joining: this.editDoctor.date_of_joining,
        notes: this.editDoctor.notes,
      };
    }
  },
  methods: {
    submit() {
      this.$v.form.$touch();
      if (this.$v.form.$pending || this.$v.form.$error) return;
      this.loading = true;

      this.$store.commit("individualFormFilling", this.form);
      if (!this.editDoctor) {
        this.$store
          .dispatch("submitIndividual")
          .then(() => {
            this.$store.commit(
              "toggleSuccessMsg",
              this.$t("registration.successMsg")
            );
            this.loading = false;
          })
          .catch((data) => {
            this.loading = false;
            this.$store.commit("toggleErrorMsg", data);
          });
      } else {
        this.$store
          .dispatch("editSubmitIndividual", this.editDoctor.id)
          .then(() => {
            this.$store.commit(
              "toggleSuccessMsg",
              this.$t("registration.successEdit")
            );
            this.loading = false;
          })
          .catch((message) => {
            this.loading = false;
            this.$store.commit("toggleErrorMsg", message);
          });
      }
    },
  },
};
</script>
