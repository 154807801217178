import Vue from 'vue';
import VueRouter from 'vue-router';
import registrationRoutes from './registration';

Vue.use(VueRouter);

const routes = [{
        path: '/',
        name: 'Home',
        component: () =>
            import ('../views/Home.vue'),
    },
    {
        path: '/About',
        name: 'About',
        component: () =>
            import ('../views/About.vue'),
    },
    {
        path: '/News',
        name: 'News',
        component: () =>
            import ('../views/News.vue'),
    },
    {
        path: '/newsDetails/:id',
        name: 'newsDetails',
        component: () =>
            import ('../views/newsDetails.vue'),
    },
    {
        path: '/Services',
        name: 'Services',
        component: () =>
            import ('../views/Services.vue'),
    },
    {
        path: '/Branches',
        name: 'Branches',
        component: () =>
            import ('../views/Branches.vue'),
    },
    {
        path: '/Structure',
        name: 'Structure',
        component: () =>
            import ('../views/Structure.vue'),
    },
    {
        path: '/Contact',
        name: 'Contact',
        component: () =>
            import ('../views/Contact.vue'),
    },
    {
        path: '/events',
        name: "Events",
        component: () =>
            import ('../views/Events.vue')
    },
    {
        path: '/eventReg/:id',
        name: "eventReg",
        component: () =>
            import ('../views/eventReg.vue')
    },
    {
        path: '/eventDetails/:id',
        name: "eventDetails",
        component: () =>
            import ('../views/eventDetails.vue')
    },
    ...registrationRoutes,
    {
        path: '*',
        redirect: '/',
    },
];

const router = new VueRouter({
    scrollBehavior() {
        return { x: 0, y: 0 };
    },
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
});

export default router;