<template>
  <div
    id="Header"
    :style="'height: ' + height + 'vh'"
    :class="!height ? 'headerHeight' : null"
    class="relative banner border-b-4 border-info flex items-center justify-center text-white text-center"
  >
    <transition name="slideButton">
      <div v-if="animated" class="w-11/12 sm:w-3/4 md:w-1/2 noneBlurTilte">
        <h1
          class="font-medium md:font-bold tracking-wide leading-relaxed text-3xl md:text-4xl"
        >
          {{ title }}
        </h1>
        <p
          class="font-normal md:font-medium px-5 mx-auto tracking-wide leading-relaxed noneBlurTilte text-md md:text-lg mt-5"
        >
          {{ description }}
        </p>
      </div>
    </transition>
    <div v-if="!height" class="absolute bottom-10 flex items-center">
      <button
        @click="scrollBottom"
        class="border border-white p-3 outline-none focus:outline-none"
      >
        <img
          class="transform transition-all duration-500 hover:translate-y-1 object-cover"
          src="../../assets/VectorBottom.svg"
          alt=""
          loading="lazy"
        />
      </button>
    </div>
  </div>
</template>
<script>
export default {
  props: ["title", "description", "height"],
  name: "Header",
  data() {
    return {
      animated: false,
    };
  },
  mounted() {
    this.animated = true;
  },
  destroy() {
    this.animated = false;
  },
  methods: {
    scrollBottom() {
      window.scrollTo(0, 500);
    },
  },
};
</script>
<style scoped lang="scss">
.headerHeight {
  height: 70vh;
  @media only screen and (max-width: 550px) {
    height: 50vh !important;
  }
}
.banner {
  background: linear-gradient(
      258.03deg,
      rgba(255, 255, 255, 0.13) 24.84%,
      rgba(255, 255, 255, 0) 93.1%
    ),
    #05314e;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
</style>
