<template>
  <div>
    <div
      class="
        releative
        border
        w-full
        lg:w-3/4
        mx-3
        lg:mx-auto
        mt-8
        border-formBorderColor
        rounded-md
        p-5
        flex flex-wrap
      "
    >
      <span class="absolute -mt-8 bg-white text-primary text-lg font-bold">
        {{ $t("registration.company.document") }}
      </span>
      <div
        class="p-5 flex items-center justify-center w-full md:w-1/2 flex-wrap"
      >
        <label
          for="statement_of_graduate"
          class="font-medium text-lg w-full flex items-center"
          ><span class="text-red-600 mx-2 text-3xl mt-2">*</span>
          {{ $t("registration.company.statement_of_graduate") }}
        </label>
        <input
          :class="defualtInput"
          class="hidden"
          ref="statement_of_graduate"
          id="statement_of_graduate"
          name="statement_of_graduate"
          accept="image/*,.pdf"
          @change="statement_of_graduateFunc"
          type="file"
          required
        />
        <div class="w-full flex items-center relative overflow-hidden">
          <button
            @click="pickstatement_of_graduateFunc"
            :class="$i18n.locale == 'ar' ? 'roinded-r' : 'rounded-l'"
            class="bg-primary focus:outline-none absolute p-2 z-20 text-white"
          >
            {{ $t("registration.company.choose_file") }}
          </button>
          <input
            @click="pickstatement_of_graduateFunc"
            type="text"
            @blur="$v.form.statement_of_graduate.$touch()"
            :class="
              defualtInput +
              ($v.form.statement_of_graduate.$error
                ? ' border-red-700'
                : 'border-inputBorder')
            "
            :placeholder="$t('registration.company.file_not_loaded_yet')"
            class="px-20"
            :value="
              form.statement_of_graduate
                ? form.statement_of_graduate.name
                : null
            "
          />
        </div>
      </div>
      <div
        class="p-5 flex items-center justify-center w-full md:w-1/2 flex-wrap"
      >
        <label
          for="better_from_education_department"
          class="font-medium text-lg w-full flex items-center"
          >{{ $t("registration.company.better_from_education_department") }}
        </label>
        <input
          :class="defualtInput"
          class="hidden"
          ref="better_from_education_department"
          id="better_from_education_department"
          name="better_from_education_department"
          accept="image/*,.pdf"
          @change="better_from_education_departmentFunc"
          type="file"
          required
        />
        <div class="w-full flex items-center relative overflow-hidden">
          <button
            @click="pickbetter_from_education_departmentFunc"
            :class="$i18n.locale == 'ar' ? 'roinded-r' : 'rounded-l'"
            class="bg-primary focus:outline-none absolute p-2 z-20 text-white"
          >
            {{ $t("registration.company.choose_file") }}
          </button>
          <input
            @click="pickbetter_from_education_departmentFunc"
            type="text"
            :class="defualtInput + 'border-inputBorder'"
            :placeholder="$t('registration.company.file_not_loaded_yet')"
            class="px-20"
            :value="
              form.better_from_education_department
                ? form.better_from_education_department.name
                : null
            "
          />
        </div>
      </div>
      <div
        class="p-5 flex items-center justify-center w-full md:w-1/2 flex-wrap"
      >
        <label
          for="better_from_quality_department"
          class="font-medium text-lg w-full"
        >
          {{ $t("registration.company.better_from_quality_department") }}
        </label>
        <input
          :class="defualtInput"
          class="hidden"
          ref="better_from_quality_department"
          id="better_from_quality_department"
          name="better_from_quality_department"
          accept="image/*,.pdf"
          @change="better_from_quality_departmentFunc"
          type="file"
          required
        />
        <div class="w-full flex items-center relative overflow-hidden">
          <button
            @click="pickbetter_from_quality_departmentFunc"
            :class="$i18n.locale == 'ar' ? 'roinded-r' : 'rounded-l'"
            class="bg-primary focus:outline-none absolute p-2 z-20 text-white"
          >
            {{ $t("registration.company.choose_file") }}
          </button>
          <input
            @click="pickbetter_from_quality_departmentFunc"
            type="text"
            :class="defualtInput + 'border-inputBorder'"
            :placeholder="$t('registration.company.file_not_loaded_yet')"
            class="px-20"
            :value="
              form.better_from_quality_department
                ? form.better_from_quality_department.name
                : null
            "
          />
        </div>
      </div>
      <div
        class="p-5 flex items-center justify-center w-full md:w-1/2 flex-wrap"
      >
        <label
          for="birth_certificate"
          class="font-medium text-lg w-full flex items-center"
          ><span class="text-red-600 mx-2 text-3xl mt-2">*</span
          >{{ $t("registration.company.birth_certificate") }}</label
        >
        <input
          :class="defualtInput"
          class="hidden"
          ref="birth_certificate"
          id="birth_certificate"
          name="birth_certificate"
          accept="image/*,.pdf"
          @change="birth_certificateFunc"
          type="file"
          required
        />
        <div class="w-full flex items-center relative overflow-hidden">
          <button
            @click="pickbirth_certificateFunc"
            :class="$i18n.locale == 'ar' ? 'roinded-r' : 'rounded-l'"
            class="bg-primary focus:outline-none absolute p-2 z-20 text-white"
          >
            {{ $t("registration.company.choose_file") }}
          </button>
          <input
            @click="pickbirth_certificateFunc"
            type="text"
            @blur="$v.form.birth_certificate.$touch()"
            :class="
              defualtInput +
              ($v.form.birth_certificate.$error
                ? ' border-red-700'
                : 'border-inputBorder')
            "
            :placeholder="$t('registration.company.file_not_loaded_yet')"
            class="px-20"
            :value="form.birth_certificate ? form.birth_certificate.name : null"
          />
        </div>
        <p
          v-if="$v.form.birth_certificate.$error"
          class="text-red-600 text-sm my-1 w-full"
        >
          {{ $t("registration.load_file") }}
        </p>
      </div>
      <div
        class="p-5 flex items-center justify-center w-full md:w-1/2 flex-wrap"
      >
        <label
          for="proof_of_identity"
          class="font-medium text-lg w-full flex items-center"
          ><span class="text-red-600 mx-2 text-3xl mt-2">*</span>
          {{ $t("registration.company.proof_of_identity") }}
        </label>
        <input
          :class="defualtInput"
          class="hidden"
          ref="proof_of_identity"
          id="proof_of_identity"
          name="proof_of_identity"
          accept="image/*,.pdf"
          @change="proof_of_identityFunc"
          type="file"
          required
        />
        <div class="w-full flex items-center relative overflow-hidden">
          <button
            @click="pickproof_of_identityFunc"
            :class="$i18n.locale == 'ar' ? 'roinded-r' : 'rounded-l'"
            class="bg-primary focus:outline-none absolute p-2 z-20 text-white"
          >
            {{ $t("registration.company.choose_file") }}
          </button>
          <input
            @click="pickproof_of_identityFunc"
            type="text"
            @blur="$v.form.proof_of_identity.$touch()"
            :class="
              defualtInput +
              ($v.form.proof_of_identity.$error
                ? ' border-red-700'
                : 'border-inputBorder')
            "
            :placeholder="$t('registration.company.file_not_loaded_yet')"
            class="px-20"
            :value="form.proof_of_identity ? form.proof_of_identity.name : null"
          />
        </div>
        <p
          v-if="$v.form.proof_of_identity.$error"
          class="text-red-600 text-sm my-1 w-full"
        >
          {{ $t("registration.load_file") }}
        </p>
      </div>
      <div
        class="p-5 flex items-center justify-center w-full md:w-1/2 flex-wrap"
      >
        <label
          for="personal_image"
          class="font-medium text-lg w-full flex items-center"
          ><span class="text-red-600 mx-2 text-3xl mt-2">*</span>
          {{ $t("registration.company.personal_image") }}
        </label>
        <input
          class="hidden"
          ref="personal_image"
          id="personal_image"
          name="personal_image"
          accept="image/*,.pdf"
          @change="personal_imageFunc"
          type="file"
          required
        />
        <div class="w-full flex items-center relative overflow-hidden">
          <button
            @click="pickpersonal_imageFunc"
            :class="$i18n.locale == 'ar' ? 'roinded-r' : 'rounded-l'"
            class="bg-primary focus:outline-none absolute p-2 z-20 text-white"
          >
            {{ $t("registration.company.choose_file") }}
          </button>
          <input
            @click="pickpersonal_imageFunc"
            type="text"
            @blur="$v.form.personal_image.$touch()"
            :class="
              defualtInput +
              ($v.form.personal_image.$error
                ? ' border-red-700'
                : 'border-inputBorder')
            "
            :placeholder="$t('registration.company.file_not_loaded_yet')"
            class="px-20"
            :value="form.personal_image ? form.personal_image.name : null"
          />
        </div>
        <p
          v-if="$v.form.personal_image.$error"
          class="text-red-600 text-sm my-1 w-full"
        >
          {{ $t("registration.load_file") }}
        </p>
      </div>
      <div
        class="p-5 flex items-center justify-center w-full md:w-1/2 flex-wrap"
      >
        <label
          for="image_of_subscription"
          class="font-medium text-lg w-full flex items-center"
        >
          {{ $t("registration.company.image_of_subscription") }}
        </label>
        <input
          :class="defualtInput"
          class="hidden"
          ref="image_of_subscription"
          id="image_of_subscription"
          name="image_of_subscription"
          accept="image/*,.pdf"
          @change="image_of_subscriptionFunc"
          type="file"
          required
        />
        <div class="w-full flex items-center relative overflow-hidden">
          <button
            @click="pickimage_of_subscriptionFunc"
            :class="$i18n.locale == 'ar' ? 'roinded-r' : 'rounded-l'"
            class="bg-primary focus:outline-none absolute p-2 z-20 text-white"
          >
            {{ $t("registration.company.choose_file") }}
          </button>
          <input
            @click="pickimage_of_subscriptionFunc"
            type="text"
            :class="defualtInput + 'border-inputBorder'"
            :placeholder="$t('registration.company.file_not_loaded_yet')"
            class="px-20"
            :value="
              form.image_of_subscription
                ? form.image_of_subscription.name
                : null
            "
          />
        </div>
      </div>
      <div
        class="p-5 flex items-center justify-center w-full md:w-1/2 flex-wrap"
      >
        <label
          for="copy_of_a_valid_license_to_practice_the_profession"
          class="font-medium text-lg w-full flex items-center"
        >
          {{
            $t(
              "registration.company.copy_of_a_valid_license_to_practice_the_profession"
            )
          }}
        </label>
        <input
          :class="defualtInput"
          class="hidden"
          ref="copy_of_a_valid_license_to_practice_the_profession"
          id="copy_of_a_valid_license_to_practice_the_profession"
          name="copy_of_a_valid_license_to_practice_the_profession"
          accept="image/*,.pdf"
          @change="copy_of_a_valid_license_to_practice_the_professionFunc"
          type="file"
          required
        />
        <div class="w-full flex items-center relative overflow-hidden">
          <button
            @click="pickcopy_of_a_valid_license_to_practice_the_professionFunc"
            :class="$i18n.locale == 'ar' ? 'roinded-r' : 'rounded-l'"
            class="bg-primary focus:outline-none absolute p-2 z-20 text-white"
          >
            {{ $t("registration.company.choose_file") }}
          </button>
          <input
            @click="pickcopy_of_a_valid_license_to_practice_the_professionFunc"
            type="text"
            :class="defualtInput + 'border-inputBorder'"
            :placeholder="$t('registration.company.file_not_loaded_yet')"
            class="px-20"
            :value="
              form.copy_of_a_valid_license_to_practice_the_profession
                ? form.copy_of_a_valid_license_to_practice_the_profession.name
                : null
            "
          />
        </div>
      </div>
      <div
        class="p-5 flex items-center justify-center w-full md:w-1/2 flex-wrap"
      >
        <label
          for="former_permission_of_company"
          class="font-medium text-lg w-full flex items-center"
        >
          {{ $t("registration.company.former_permission_of_company") }}
        </label>
        <input
          class="hidden"
          ref="former_permission_of_company"
          id="former_permission_of_company"
          name="former_permission_of_company"
          accept="image/*,.pdf"
          @change="former_permission_of_companyFunc"
          type="file"
          required
        />
        <div class="w-full flex items-center relative overflow-hidden">
          <button
            @click="pickformer_permission_of_companyFunc"
            :class="$i18n.locale == 'ar' ? 'roinded-r' : 'rounded-l'"
            class="bg-primary focus:outline-none absolute p-2 z-20 text-white"
          >
            {{ $t("registration.company.choose_file") }}
          </button>
          <input
            @click="pickformer_permission_of_companyFunc"
            type="text"
            :class="defualtInput + 'border-inputBorder'"
            :placeholder="$t('registration.company.file_not_loaded_yet')"
            class="px-20"
            :value="
              form.former_permission_of_company
                ? form.former_permission_of_company.name
                : null
            "
          />
        </div>
      </div>
      <div
        class="p-5 flex items-center justify-center w-full md:w-1/2 flex-wrap"
      >
        <label
          for="experience_certificate"
          class="font-medium text-lg w-full flex items-center"
        >
          {{ $t("registration.company.experience_certificate") }}
        </label>
        <input
          class="hidden"
          ref="experience_certificate"
          id="experience_certificate"
          name="experience_certificate"
          accept="image/*,.pdf"
          @change="experience_certificateFunc"
          type="file"
          required
        />
        <div class="w-full flex items-center relative overflow-hidden">
          <button
            @click="pickexperience_certificateFunc"
            :class="$i18n.locale == 'ar' ? 'roinded-r' : 'rounded-l'"
            class="bg-primary focus:outline-none absolute p-2 z-20 text-white"
          >
            {{ $t("registration.company.choose_file") }}
          </button>
          <input
            @click="pickexperience_certificateFunc"
            type="text"
            :class="defualtInput + 'border-inputBorder'"
            :placeholder="$t('registration.company.file_not_loaded_yet')"
            class="px-20"
            :value="
              form.experience_certificate
                ? form.experience_certificate.name
                : null
            "
          />
        </div>
      </div>
      <div
        class="p-5 flex items-center justify-center w-full md:w-1/2 flex-wrap"
      >
        <label
          for="contract_of_establishment_of_company"
          class="font-medium text-lg w-full flex items-center"
        >
          {{ $t("registration.company.contract_of_establishment_of_company") }}
        </label>
        <input
          class="hidden"
          ref="contract_of_establishment_of_company"
          id="contract_of_establishment_of_company"
          name="contract_of_establishment_of_company"
          accept="image/*,.pdf"
          @change="contract_of_establishment_of_companyFunc"
          type="file"
          required
        />
        <div class="w-full flex items-center relative overflow-hidden">
          <button
            @click="pickcontract_of_establishment_of_companyFunc"
            :class="$i18n.locale == 'ar' ? 'roinded-r' : 'rounded-l'"
            class="bg-primary focus:outline-none absolute p-2 z-20 text-white"
          >
            {{ $t("registration.company.choose_file") }}
          </button>
          <input
            @click="pickcontract_of_establishment_of_companyFunc"
            type="text"
            :class="defualtInput + 'border-inputBorder'"
            :placeholder="$t('registration.company.file_not_loaded_yet')"
            class="px-20"
            :value="
              form.contract_of_establishment_of_company
                ? form.contract_of_establishment_of_company.name
                : null
            "
          />
        </div>
      </div>
      <div
        class="p-5 flex items-center justify-center w-full md:w-1/2 flex-wrap"
      >
        <label
          for="minutes_of_the_meeting"
          class="font-medium text-lg w-full flex items-center"
        >
          {{ $t("registration.company.minutes_of_the_meeting") }}
        </label>
        <input
          class="hidden"
          ref="minutes_of_the_meeting"
          id="minutes_of_the_meeting"
          name="minutes_of_the_meeting"
          accept="image/*,.pdf"
          @change="minutes_of_the_meetingFunc"
          type="file"
          required
        />
        <div class="w-full flex items-center relative overflow-hidden">
          <button
            @click="pickminutes_of_the_meetingFunc"
            :class="$i18n.locale == 'ar' ? 'roinded-r' : 'rounded-l'"
            class="bg-primary focus:outline-none absolute p-2 z-20 text-white"
          >
            {{ $t("registration.company.choose_file") }}
          </button>
          <input
            @click="pickminutes_of_the_meetingFunc"
            type="text"
            :class="defualtInput + 'border-inputBorder'"
            :placeholder="$t('registration.company.file_not_loaded_yet')"
            class="px-20"
            :value="
              form.minutes_of_the_meeting
                ? form.minutes_of_the_meeting.name
                : null
            "
          />
        </div>
      </div>
      <div
        class="p-5 flex items-center justify-center w-full md:w-1/2 flex-wrap"
      >
        <label
          for="the_general_assembly"
          class="font-medium text-lg w-full flex items-center"
        >
          {{ $t("registration.company.the_general_assembly") }}
        </label>
        <input
          class="hidden"
          ref="the_general_assembly"
          id="the_general_assembly"
          name="the_general_assembly"
          accept="image/*,.pdf"
          @change="the_general_assemblyFunc"
          type="file"
          required
        />
        <div class="w-full flex items-center relative overflow-hidden">
          <button
            @click="pickthe_general_assemblyFunc"
            :class="$i18n.locale == 'ar' ? 'roinded-r' : 'rounded-l'"
            class="bg-primary focus:outline-none absolute p-2 z-20 text-white"
          >
            {{ $t("registration.company.choose_file") }}
          </button>
          <input
            @click="pickthe_general_assemblyFunc"
            type="text"
            :class="defualtInput + 'border-inputBorder'"
            :placeholder="$t('registration.company.file_not_loaded_yet')"
            class="px-20"
            :value="
              form.the_general_assembly ? form.the_general_assembly.name : null
            "
          />
        </div>
      </div>
      <div
        class="p-5 flex items-center justify-center w-full md:w-1/2 flex-wrap"
      >
        <label
          for="commercial_record"
          class="font-medium text-lg w-full flex items-center"
        >
          {{ $t("registration.company.commercial_record") }}
        </label>
        <input
          class="hidden"
          ref="commercial_record"
          id="commercial_record"
          name="commercial_record"
          accept="image/*,.pdf"
          @change="commercial_recordFunc"
          type="file"
          required
        />
        <div class="w-full flex items-center relative overflow-hidden">
          <button
            @click="pickcommercial_recordFunc"
            :class="$i18n.locale == 'ar' ? 'roinded-r' : 'rounded-l'"
            class="bg-primary focus:outline-none absolute p-2 z-20 text-white"
          >
            {{ $t("registration.company.choose_file") }}
          </button>
          <input
            @click="pickcommercial_recordFunc"
            type="text"
            :class="defualtInput + 'border-inputBorder'"
            :placeholder="$t('registration.company.file_not_loaded_yet')"
            class="px-20"
            :value="form.commercial_record ? form.commercial_record.name : null"
          />
        </div>
      </div>
      <div
        class="p-5 flex items-center justify-center w-full md:w-1/2 flex-wrap"
      >
        <label
          for="chamber_of_commerce"
          class="font-medium text-lg w-full flex items-center"
        >
          {{ $t("registration.company.chamber_of_commerce") }}
        </label>
        <input
          class="hidden"
          ref="chamber_of_commerce"
          id="chamber_of_commerce"
          name="chamber_of_commerce"
          accept="image/*,.pdf"
          @change="chamber_of_commerceFunc"
          type="file"
          required
        />
        <div class="w-full flex items-center relative overflow-hidden">
          <button
            @click="pickchamber_of_commerceFunc"
            :class="$i18n.locale == 'ar' ? 'roinded-r' : 'rounded-l'"
            class="bg-primary focus:outline-none absolute p-2 z-20 text-white"
          >
            {{ $t("registration.company.choose_file") }}
          </button>
          <input
            @click="pickchamber_of_commerceFunc"
            type="text"
            :class="defualtInput + 'border-inputBorder'"
            :placeholder="$t('registration.company.file_not_loaded_yet')"
            class="px-20"
            :value="
              form.chamber_of_commerce ? form.chamber_of_commerce.name : null
            "
          />
        </div>
      </div>
      <div
        class="p-5 flex items-center justify-center w-full md:w-1/2 flex-wrap"
      >
        <label for="former_license" class="font-medium text-lg w-full">{{
          $t("registration.company.former_license")
        }}</label>
        <input
          class="hidden"
          ref="former_license"
          id="former_license"
          name="former_license"
          accept="image/*,.pdf"
          @change="former_licenseFunc"
          type="file"
          required
        />
        <div class="w-full flex items-center relative overflow-hidden">
          <button
            @click="pickformer_licenseFunc"
            :class="$i18n.locale == 'ar' ? 'roinded-r' : 'rounded-l'"
            class="bg-primary focus:outline-none absolute p-2 z-20 text-white"
          >
            {{ $t("registration.company.choose_file") }}
          </button>
          <input
            @click="pickformer_licenseFunc"
            type="text"
            :class="defualtInput + 'border-inputBorder'"
            :placeholder="$t('registration.company.file_not_loaded_yet')"
            class="px-20"
            :value="form.former_license ? form.former_license.name : null"
          />
        </div>
      </div>
    </div>
    <div class="flex w-full lg:w-3/4 mx-auto items-center justify-end">
      <p v-if="$v.form.$error" class="text-red-600 text-md font-medium">
        {{ $t("registration.completeReqMsg") }}
      </p>
      <button
        @click="$router.go(-1)"
        class="
          px-8
          rounded-md
          w-auto
          shadow
          py-2
          focus:outline-none
          hover:shadow-lg
          m-3
          md:m-5
          border border-primary
          text-primary
          bg-white
          text-lg
          font-medium
        "
      >
        {{ $t("registration.back") }}
      </button>
      <button
        @click.prevent="submit"
        class="
          px-8
          rounded-md
          w-auto
          shadow
          py-2
          flex
          items-center
          focus:outline-none
          hover:shadow-lg
          m-3
          md:m-5
          bg-primary
          text-white text-lg
          font-medium
        "
      >
        <span>{{ $t("registration.submit") }} </span
        ><i
          v-if="loading"
          class="text-2xl mx-2 bx bx-loader-circle bx-spin"
        ></i>
      </button>
    </div>
  </div>
</template>
<script>
import { required } from "vuelidate/lib/validators";
export default {
  data() {
    return {
      defualtInput:
        "p-2  bg-white rounded-md border w-full  focus-within:border-primary hover:border-primary focus:border-primary focus:outline-none focus:shadow-md my-1",
      loading: false,
      form: {
        statement_of_graduate: "",
        better_from_education_department: "",
        better_from_quality_department: "",
        birth_certificate: "",
        proof_of_identity: "",
        personal_image: "",
        image_of_subscription: "",
        minutes_of_the_meeting: "",
        the_general_assembly: "",
        copy_of_a_valid_license_to_practice_the_profession: "",
        former_permission_of_company: "",
        experience_certificate: "",
        contract_of_establishment_of_company: "",
        commercial_record: "",
        chamber_of_commerce: "",
        former_license: "",
      },
    };
  },
  validations: {
    form: {
      statement_of_graduate: { required },
      birth_certificate: { required },
      proof_of_identity: { required },
      personal_image: { required },
      former_permission_of_company: { required },
    },
  },
  computed: {
    editCompany() {
      return this.$store.getters.editCompany;
    },
    companyData() {
      return this.$store.getters.companyData;
    },
  },
  created() {
    if (this.companyData) {
      this.form = { ...this.form, ...this.companyData };
    }
  },
  mounted() {
    if (this.editCompany) {
      this.form = {
        statement_of_graduate: this.editCompany.statement_of_graduate,
        better_from_education_department:
          this.editCompany.better_from_education_department,
        better_from_quality_department:
          this.editCompany.better_from_quality_department,
        birth_certificate: this.editCompany.birth_certificate,
        minutes_of_the_meeting: this.editCompany.minutes_of_the_meeting,
        proof_of_identity: this.editCompany.proof_of_identity,
        the_general_assembly: this.editCompany.the_general_assembly,
        personal_image: this.editCompany.personal_image,
        copy_of_a_valid_license_to_practice_the_profession:
          this.editCompany.copy_of_a_valid_license_to_practice_the_profession,
        image_of_subscription: this.editCompany.image_of_subscription,
        former_permission_of_company:
          this.editCompany.former_permission_of_company,
        experience_certificate: this.editCompany.experience_certificate,
        contract_of_establishment_of_company:
          this.editCompany.contract_of_establishment_of_company,
        commercial_record: this.editCompany.commercial_record,
        chamber_of_commerce: this.editCompany.chamber_of_commerce,
        former_license: this.editCompany.former_license,
      };
    }
  },
  methods: {
    pickstatement_of_graduateFunc() {
      this.$refs.statement_of_graduate.click();
    },
    pickbetter_from_education_departmentFunc() {
      this.$refs.better_from_education_department.click();
    },
    pickcopy_of_a_valid_license_to_practice_the_professionFunc() {
      this.$refs.copy_of_a_valid_license_to_practice_the_profession.click();
    },
    pickbetter_from_quality_departmentFunc() {
      this.$refs.better_from_quality_department.click();
    },
    pickbirth_certificateFunc() {
      this.$refs.birth_certificate.click();
    },
    pickthe_general_assemblyFunc() {
      this.$refs.the_general_assembly.click();
    },
    pickproof_of_identityFunc() {
      this.$refs.proof_of_identity.click();
    },
    pickpersonal_imageFunc() {
      this.$refs.personal_image.click();
    },
    pickimage_of_subscriptionFunc() {
      this.$refs.image_of_subscription.click();
    },
    pickformer_permission_of_companyFunc() {
      this.$refs.former_permission_of_company.click();
    },
    pickexperience_certificateFunc() {
      this.$refs.experience_certificate.click();
    },
    pickcontract_of_establishment_of_companyFunc() {
      this.$refs.contract_of_establishment_of_company.click();
    },
    pickminutes_of_the_meetingFunc() {
      this.$refs.minutes_of_the_meeting.click();
    },
    pickcommercial_recordFunc() {
      this.$refs.commercial_record.click();
    },
    pickchamber_of_commerceFunc() {
      this.$refs.chamber_of_commerce.click();
    },
    pickformer_licenseFunc() {
      this.$refs.former_license.click();
    },
    statement_of_graduateFunc(e) {
      if (!e.target.files.length) return;
      const file = e.target.files[0].type;
      if (
        file == "image/png" ||
        file == "application/pdf" ||
        file == "image/jpeg" ||
        file == "image/jpg"
      ) {
        this.form.statement_of_graduate = e.target.files[0];
        this.$store.commit("companyFormFilling", this.form);
      } else {
        let message = [this.$t("registration.company.imgValidation")];
        this.$store.commit("toggleErrorMsg", message);
      }
    },
    minutes_of_the_meetingFunc(e) {
      if (!e.target.files.length) return;
      const file = e.target.files[0].type;
      if (
        file == "image/png" ||
        file == "application/pdf" ||
        file == "image/jpeg" ||
        file == "image/jpg"
      ) {
        this.form.minutes_of_the_meeting = e.target.files[0];
        this.$store.commit("companyFormFilling", this.form);
      } else {
        let message = [this.$t("registration.company.imgValidation")];
        this.$store.commit("toggleErrorMsg", message);
      }
    },
    better_from_education_departmentFunc(e) {
      if (!e.target.files.length) return;
      const file = e.target.files[0].type;
      if (
        file == "image/png" ||
        file == "application/pdf" ||
        file == "image/jpeg" ||
        file == "image/jpg"
      ) {
        this.form.better_from_education_department = e.target.files[0];
        this.$store.commit("companyFormFilling", this.form);
      } else {
        let message = [this.$t("registration.company.imgValidation")];
        this.$store.commit("toggleErrorMsg", message);
      }
    },
    better_from_quality_departmentFunc(e) {
      if (!e.target.files.length) return;
      const file = e.target.files[0].type;
      if (
        file == "image/png" ||
        file == "application/pdf" ||
        file == "image/jpeg" ||
        file == "image/jpg"
      ) {
        this.form.better_from_quality_department = e.target.files[0];
        this.$store.commit("companyFormFilling", this.form);
      } else {
        let message = [this.$t("registration.company.imgValidation")];
        this.$store.commit("toggleErrorMsg", message);
      }
    },
    birth_certificateFunc(e) {
      if (!e.target.files.length) return;

      const file = e.target.files[0].type;
      if (
        file == "image/png" ||
        file == "application/pdf" ||
        file == "image/jpeg" ||
        file == "image/jpg"
      ) {
        this.form.birth_certificate = e.target.files[0];
        this.$store.commit("companyFormFilling", this.form);
      } else {
        let message = [this.$t("registration.company.imgValidation")];
        this.$store.commit("toggleErrorMsg", message);
      }
    },
    proof_of_identityFunc(e) {
      if (!e.target.files.length) return;

      const file = e.target.files[0].type;
      if (
        file == "image/png" ||
        file == "application/pdf" ||
        file == "image/jpeg" ||
        file == "image/jpg"
      ) {
        this.form.proof_of_identity = e.target.files[0];
        this.$store.commit("companyFormFilling", this.form);
      } else {
        let message = [this.$t("registration.company.imgValidation")];
        this.$store.commit("toggleErrorMsg", message);
      }
    },
    personal_imageFunc(e) {
      if (!e.target.files.length) return;

      const file = e.target.files[0].type;
      if (
        file == "image/png" ||
        file == "application/pdf" ||
        file == "image/jpeg" ||
        file == "image/jpg"
      ) {
        this.form.personal_image = e.target.files[0];
        this.$store.commit("companyFormFilling", this.form);
      } else {
        let message = [this.$t("registration.company.imgValidation")];
        this.$store.commit("toggleErrorMsg", message);
      }
    },
    image_of_subscriptionFunc(e) {
      if (!e.target.files.length) return;

      const file = e.target.files[0].type;
      if (
        file == "image/png" ||
        file == "application/pdf" ||
        file == "image/jpeg" ||
        file == "image/jpg"
      ) {
        this.form.image_of_subscription = e.target.files[0];
        this.$store.commit("companyFormFilling", this.form);
      } else {
        let message = [this.$t("registration.company.imgValidation")];
        this.$store.commit("toggleErrorMsg", message);
      }
    },
    former_permission_of_companyFunc(e) {
      if (!e.target.files.length) return;

      const file = e.target.files[0].type;
      if (
        file == "image/png" ||
        file == "application/pdf" ||
        file == "image/jpeg" ||
        file == "image/jpg"
      ) {
        this.form.former_permission_of_company = e.target.files[0];
        this.$store.commit("companyFormFilling", this.form);
      } else {
        let message = [this.$t("registration.company.imgValidation")];
        this.$store.commit("toggleErrorMsg", message);
      }
    },
    experience_certificateFunc(e) {
      if (!e.target.files.length) return;

      const file = e.target.files[0].type;
      if (
        file == "image/png" ||
        file == "application/pdf" ||
        file == "image/jpeg" ||
        file == "image/jpg"
      ) {
        this.form.experience_certificate = e.target.files[0];
        this.$store.commit("companyFormFilling", this.form);
      } else {
        let message = [this.$t("registration.company.imgValidation")];
        this.$store.commit("toggleErrorMsg", message);
      }
    },
    contract_of_establishment_of_companyFunc(e) {
      if (!e.target.files.length) return;

      const file = e.target.files[0].type;
      if (
        file == "image/png" ||
        file == "application/pdf" ||
        file == "image/jpeg" ||
        file == "image/jpg"
      ) {
        this.form.contract_of_establishment_of_company = e.target.files[0];
        this.$store.commit("companyFormFilling", this.form);
      } else {
        let message = [this.$t("registration.company.imgValidation")];
        this.$store.commit("toggleErrorMsg", message);
      }
    },
    commercial_recordFunc(e) {
      if (!e.target.files.length) return;

      const file = e.target.files[0].type;
      if (
        file == "image/png" ||
        file == "application/pdf" ||
        file == "image/jpeg" ||
        file == "image/jpg"
      ) {
        this.form.commercial_record = e.target.files[0];
        this.$store.commit("companyFormFilling", this.form);
      } else {
        let message = [this.$t("registration.company.imgValidation")];
        this.$store.commit("toggleErrorMsg", message);
      }
    },
    copy_of_a_valid_license_to_practice_the_professionFunc(e) {
      if (!e.target.files.length) return;

      const file = e.target.files[0].type;
      if (
        file == "image/png" ||
        file == "application/pdf" ||
        file == "image/jpeg" ||
        file == "image/jpg"
      ) {
        this.form.copy_of_a_valid_license_to_practice_the_profession =
          e.target.files[0];
        this.$store.commit("companyFormFilling", this.form);
      } else {
        let message = [this.$t("registration.company.imgValidation")];
        this.$store.commit("toggleErrorMsg", message);
      }
    },
    chamber_of_commerceFunc(e) {
      if (!e.target.files.length) return;

      const file = e.target.files[0].type;
      if (
        file == "image/png" ||
        file == "application/pdf" ||
        file == "image/jpeg" ||
        file == "image/jpg"
      ) {
        this.form.chamber_of_commerce = e.target.files[0];
        this.$store.commit("companyFormFilling", this.form);
      } else {
        let message = [this.$t("registration.company.imgValidation")];
        this.$store.commit("toggleErrorMsg", message);
      }
    },
    former_licenseFunc(e) {
      if (!e.target.files.length) return;

      const file = e.target.files[0].type;
      if (
        file == "image/png" ||
        file == "application/pdf" ||
        file == "image/jpeg" ||
        file == "image/jpg"
      ) {
        this.form.former_license = e.target.files[0];
        this.$store.commit("companyFormFilling", this.form);
      } else {
        let message = [this.$t("registration.company.imgValidation")];
        this.$store.commit("toggleErrorMsg", message);
      }
    },
    the_general_assemblyFunc(e) {
      if (!e.target.files.length) return;

      const file = e.target.files[0].type;
      if (
        file == "image/png" ||
        file == "application/pdf" ||
        file == "image/jpeg" ||
        file == "image/jpg"
      ) {
        this.form.the_general_assembly = e.target.files[0];
        this.$store.commit("companyFormFilling", this.form);
      } else {
        let message = [this.$t("registration.company.imgValidation")];
        this.$store.commit("toggleErrorMsg", message);
      }
    },
    submit() {
      this.$v.form.$touch();
      if (this.$v.form.$pending || this.$v.form.$error) return;
      this.loading = true;
      this.$store.commit("companyFormFilling", this.form);
      this.$store
        .dispatch("companySubmiting")
        .then(() => {
          this.loading = false;
          this.$store.commit(
            "toggleSuccessMsg",
            this.$t("registration.company.successMsg")
          );
        })
        .catch((message) => {
          this.loading = false;
          this.$store.commit("toggleErrorMsg", message);
        });
    },
  },
};
</script>
