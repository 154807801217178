<template>
	<div class="lg:relative py-12 h-auto lg:h-64">
		<div class="lg:absolute h-full -bottom-24 w-full">
			<div class="bg-secondry container mx-auto py-10 px-8 lg:p-12 text-center">
				<h2 class="text-white text-3xl font-medium">{{ $t('subscripe.h1') }}</h2>
				<p class="text-subscribeText mt-3 text-lg">
					{{ $t('subscripe.p') }}
				</p>
				<div class="flex items-center justify-center mt-5 md:w-1/2 mx-auto">
					<input
						type="email"
						id="email"
						v-model="email"
						name="email"
						class="bg-white py-3 px-5 w-3/4"
						:placeholder="$t('subscripe.placeholder')"
					/>
					<button @click="submit" class="bg-btn py-3 px-5 text-white focus:outline-none">
						<span v-if="!loading">{{ $t('subscripe.btn') }}</span>
						<i v-else class="bx bx-loader-circle bx-spin text-lg text-white"></i>
					</button>
				</div>
				<div v-if="success" class="flex items-center justify-center mt-5 text-center text-white text-xl">
					<span class="mx-2"> {{ successMsg }}</span>
					<i class="bx bx-check-double text-3xl"></i>
				</div>
				<div
					v-if="error"
					class="flex items-center justify-center mt-5 w-full mx-auto text-center font-medium text-white text-md tracking-wide leading-relaxed"
				>
					<span class="mx-2"> {{ error }}</span>
					<i class="bx bx-message-rounded-error text-2xl"></i>
				</div>
				<div v-if="$v.email.$error || $v.email.$pending">
					<p
						class="flex items-center justify-center text-white font-medium mt-2 text-md tracking-wide leading-relaxed"
						v-if="!$v.email.email && $v.email.required"
					>
						<span> {{ $t('subscripe.emailVerfied') }}</span>
						<i class="bx bx-message-rounded-error text-2xl mx-2"></i>
					</p>
					<p
						v-if="!$v.email.required"
						class="flex items-center justify-center text-white font-medium mt-2 text-md tracking-wide leading-relaxed"
					>
						<span>{{ $t('subscripe.emailRequired') }}</span>
						<i class="bx bx-message-rounded-error text-2xl mx-2"></i>
					</p>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import { required, email } from 'vuelidate/lib/validators';

export default {
	name: 'Subscribe',
	data() {
		return {
			loading: false,
			success: '',
			successMsg: this.$t('subscripe.successMsg'),
			email: '',
			error: '',
		};
	},
	validations: {
		email: { required, email },
	},
	destroye() {
		this.$v.email.$reset();
	},
	methods: {
		async reset() {
			let time = 8000;
			await setTimeout(() => {
				this.$v.email.$reset();
			}, time);
		},
		submit() {
			this.$v.email.$touch();
			if (this.$v.email.$pending || this.$v.email.$error) {
				this.reset();
				return;
			}
			this.loading = true;
			this.$store
				.dispatch('subscription', { email: this.email })
				.then((res) => {
					this.loading = false;
					if (res.message == 'تم الستجيل بنجاح') {
						this.error = '';
						this.success = true;
						this.email = '';
						this.$v.email.$reset();
					}
				})
				.catch((error) => {
					this.loading = false;
					error.data.forEach((err) => {
						if (err == 'The email has already been taken.') {
							this.error = this.$t('subscripe.errMsg');
						}
					});
					setTimeout(() => {
						this.error = '';
					}, 8000);
				});
		},
	},
};
</script>
