<template>
  <div>
    <div
      class="
        releative
        border
        w-full
        lg:w-3/4
        mx-3
        lg:mx-auto
        mt-8
        border-formBorderColor
        rounded-md
        p-5
        flex flex-wrap
      "
    >
      <span class="absolute -mt-8 bg-white text-primary text-lg font-bold">
        {{ $t("registration.basic_information") }}
      </span>
      <div
        v-if="Branches"
        class="p-5 flex items-center justify-center w-full md:w-1/2 flex-wrap"
      >
        <label for="date" class="font-medium text-lg w-full flex items-center"
          ><span class="text-red-600 mx-2 text-3xl mt-2">*</span>
          {{ $t("registration.company.branch") }}</label
        >
        <v-select
          v-if="Branches"
          :options="Branches"
          :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
          :label="$i18n.locale == 'ar' ? 'name_ar' : 'name_en'"
          id="branch_id"
          :clearable="false"
          @blur="$v.form.branch_id.$touch()"
          name="branch_id"
          v-model="form.branch_id"
          :reduce="(branch_id) => branch_id.id"
          :class="
            defualtInput +
            ($v.form.branch_id.$error
              ? ' border-red-700'
              : 'border-inputBorder')
          "
          class="p-0"
        ></v-select>
        <p
          v-if="$v.form.branch_id.$error"
          class="text-red-600 text-sm my-1 w-full"
        >
          {{ $t("registration.reqMsg") }}
        </p>
      </div>
      <div
        class="p-5 flex items-center justify-center w-full md:w-1/2 flex-wrap"
      >
        <label
          for="registration_number"
          class="font-medium text-lg w-full text-h1Text flex items-center"
        >
          {{ $t("registration.company.id") }}</label
        >
        <input
          id="registration_number"
          name="registration_number"
          v-model.number="form.registration_number"
          type="number"
          required
          :class="defualtInput + 'border-inputBorder'"
        />
      </div>
      <div
        class="p-5 flex items-center justify-center w-full md:w-1/2 flex-wrap"
      >
        <label
          for="company_type"
          class="font-medium text-lg w-full text-right flex items-center"
          ><span class="text-red-600 mx-2 text-3xl mt-2">*</span>
          {{ $t("registration.company.type") }}
          </label
        >
        <v-select
          :options="['شركة استيراد', 'شركة خدمات']"
          dir="rtl"
          id="company_type"
          @blur="$v.form.company_type.$touch()"
          name="company_type"
          v-model="form.company_type"
          :class="
            defualtInput +
            ($v.form.company_type.$error
              ? ' border-red-700'
              : 'border-inputBorder')
          "
          class="p-0"
        ></v-select>
        <p
          v-if="$v.form.company_type.$error"
          class="text-red-600 text-sm my-1 w-full"
        >
          الرجاء تعبئة هذا الحقل
        </p>
      </div>
      <div
        class="p-5 flex items-center justify-center w-full md:w-1/2 flex-wrap"
      >
        <label
          for="registration_date"
          class="font-medium text-lg w-full text-h1Text flex items-center"
        >
          {{ $t("registration.company.id_registration") }}
        </label>
        <datetime
          id="registration_date"
          name="registration_date"
          v-model="form.registration_date"
          format="yyyy-MM-dd"
          :max-datetime="maxDate"
          :class="defualtInput + 'border-inputBorder'"
          type="text"
        >
        </datetime>
      </div>
      <div
        class="p-5 flex items-center justify-center w-full md:w-1/2 flex-wrap"
      >
        <label
          for="company_name"
          class="font-medium text-lg w-full text-h1Text flex items-center"
          ><span class="text-red-600 mx-2 text-3xl mt-2">*</span>
          {{ $t("registration.company.company_name") }}
        </label>
        <input
          id="company_name"
          name="company_name"
          v-model="form.company_name"
          type="text"
          required
          @blur="$v.form.company_name.$touch()"
          :class="
            defualtInput +
            ($v.form.company_name.$error
              ? ' border-red-700'
              : 'border-inputBorder')
          "
        />
        <p
          v-if="$v.form.company_name.$error"
          class="text-red-600 text-sm my-1 w-full"
        >
          {{ $t("registration.reqMsg") }}
        </p>
      </div>

      <div
        class="p-5 flex items-center justify-center w-full md:w-1/2 flex-wrap"
      >
        <label
          for="phone_number"
          class="font-medium text-lg w-full text-h1Text flex items-center"
          ><span class="text-red-600 mx-2 text-3xl mt-2">*</span>
          {{ $t("registration.company.phone_number") }}</label
        >
        <input
          id="phone_number"
          name="phone_number"
          v-model="form.phone_number"
          type="number"
          required
          @blur="$v.form.phone_number.$touch()"
          :class="
            defualtInput +
            ($v.form.phone_number.$error
              ? ' border-red-700'
              : 'border-inputBorder')
          "
        />
        <p
          v-if="$v.form.phone_number.$error && !$v.form.phone_number.required"
          class="text-red-600 text-sm my-1 w-full"
        >
          {{ $t("registration.reqMsg") }}
        </p>
        <p
          v-if="!$v.form.phone_number.minLength"
          class="text-red-600 text-sm my-1 w-full"
        >
          {{ $t("registration.phone_format") }}
        </p>
      </div>
      <div
        class="p-5 flex items-center justify-center w-full md:w-1/2 flex-wrap"
      >
        <label
          for="mobile_number"
          class="font-medium text-lg w-full text-h1Text flex items-center"
        >
          {{ $t("registration.company.phone_number") + " 2 " }}
        </label>
        <input
          id="mobile_number"
          name="mobile_number"
          v-model="form.mobile_number"
          type="number"
          required
          @blur="$v.form.mobile_number.$touch()"
          :class="
            defualtInput +
            ($v.form.mobile_number.$error
              ? ' border-red-700'
              : 'border-inputBorder')
          "
        />

        <p
          v-if="!$v.form.mobile_number.minLength"
          class="text-red-600 text-sm my-1 w-full"
        >
          {{ $t("registration.phone_format") }}
        </p>
      </div>
      <div
        class="p-5 flex items-center justify-center w-full md:w-1/2 flex-wrap"
      >
        <label
          for="email"
          class="font-medium text-lg w-full text-h1Text flex items-center"
        >
          {{ $t("registration.company.email") }}</label
        >
        <input
          id="email"
          name="email"
          v-model="form.email"
          type="email"
          required
          :class="defualtInput + 'border-inputBorder'"
        />
      </div>
      <div
        class="p-5 flex items-center justify-center w-full md:w-1/2 flex-wrap"
      >
        <label for="website" class="font-medium text-lg w-full text-h1Text">
          {{ $t("registration.company.website") }}</label
        >
        <input
          id="website"
          name="website"
          v-model="form.website"
          type="url"
          @blur="$v.form.website.$touch()"
          :class="
            defualtInput +
            ($v.form.website.$error ? ' border-red-700' : 'border-inputBorder')
          "
        />
        <p
          v-if="$v.form.website.$error"
          class="text-red-600 text-sm my-1 w-full"
        >
          {{ $t("registration.url_confirm") }}
        </p>
      </div>
      <div
        class="p-5 flex items-center justify-center w-full md:w-1/2 flex-wrap"
      >
        <label
          for="license_number"
          class="font-medium text-lg w-full text-h1Text flex items-center"
          >{{ $t("registration.company.license_number") }}</label
        >
        <input
          id="license_number"
          name="license_number"
          v-model.number="form.license_number"
          type="number"
          required
          :class="defualtInput + 'border-inputBorder'"
        />
      </div>
      <div
        class="p-5 flex items-center justify-center w-full md:w-1/2 flex-wrap"
      >
        <label
          for="Issuer"
          class="font-medium text-lg w-full text-h1Text flex items-center"
          >{{ $t("registration.company.Where_license_issued") }}</label
        >
        <input
          id="Issuer"
          name="Issuer"
          v-model="form.Issuer"
          type="text"
          required
          :class="defualtInput + 'border-inputBorder'"
        />
      </div>
      <div
        class="p-5 flex items-center justify-center w-full md:w-1/2 flex-wrap"
      >
        <label
          for="chamber_of_commerce_registration_number"
          class="font-medium text-lg w-full text-h1Text flex items-center"
          >{{
            $t("registration.company.Chamber_of_Commerce_registration_number")
          }}</label
        >
        <input
          id="chamber_of_commerce_registration_number"
          name="chamber_of_commerce_registration_number"
          v-model.number="form.chamber_of_commerce_registration_number"
          type="number"
          required
          :class="defualtInput + 'border-inputBorder'"
        />
      </div>
    </div>
    <div class="flex w-full lg:w-3/4 mx-auto items-center justify-end">
      <p v-if="$v.form.$error" class="text-red-600 text-md font-medium">
        {{ $t("registration.completeReqMsg") }}
      </p>
      <button
        @click="$router.go(-1)"
        class="
          px-8
          rounded-md
          w-auto
          shadow
          py-2
          focus:outline-none
          hover:shadow-lg
          m-3
          md:m-5
          border border-primary
          text-primary
          bg-white
          text-lg
          font-medium
        "
      >
        {{ $t("registration.back") }}
      </button>
      <button
        @click="next"
        class="
          px-8
          rounded-md
          w-auto
          shadow
          py-2
          focus:outline-none
          hover:shadow-lg
          m-3
          md:m-5
          bg-primary
          text-white text-lg
          font-medium
        "
      >
        {{ $t("registration.next") }}
      </button>
    </div>
  </div>
</template>
<script>
import {
  required,
  email,
  numeric,
  url,
  minLength,
} from "vuelidate/lib/validators";
import moment from "moment";
export default {
  name: "companyGeneral",
  data() {
    return {
      defualtInput:
        "p-2  bg-white rounded-md border w-full  focus-within:border-primary hover:border-primary focus:border-primary focus:outline-none focus:shadow-md my-1",
      maxDate: moment().format("L"),
      form: {
        branch_id: "",
        company_type: "",
        registration_number: "",
        registration_date: "",
        company_name: "",
        phone_number: "",
        mobile_number: "",
        email: "",
        website: "",
        license_number: "",
        Issuer: "",
        chamber_of_commerce_registration_number: "",
      },
    };
  },
  validations() {
    return {
      form: {
        branch_id: { required, numeric },
        company_type: { required },
        company_name: { required },
        website: { url },
        email: { email },
        phone_number: { numeric, required, minLength: minLength(10) },
        mobile_number: { numeric, minLength: minLength(10) },
      },
    };
  },
  created() {
    this.$store.dispatch("getBranches");
  },
  computed: {
    Branches() {
      return this.$store.getters.getBranches;
    },
  },
  methods: {
    next() {
      this.$v.form.$touch();
      if (this.$v.form.$pending || this.$v.form.$error) return;

      this.$store.commit("companyFormFilling", this.form);
      this.$router.push({ name: "companyPersonal" });
    },
  },
};
</script>
