<template>
  <div class="w-full h-full">
    <div class="w-full h-full flex flex-wrap mt-5">
      <div
        class="
          releative
          border
          w-full
          lg:w-3/4
          mx-3
          lg:mx-auto
          mt-8
          border-formBorderColor
          rounded-md
          p-5
          flex flex-wrap
        "
      >
        <span class="absolute -mt-8 bg-white text-primary text-lg font-bold">
          {{ $t("registration.basic_information") }}
        </span>
        <div class="w-full m-5">
          <input
            type="file"
            class="hidden"
            ref="uploadImg"
            accept="image/*"
            @change="OnPickerChange"
          />
          <div class="relative h-48 w-40">
            <i
              v-if="imageSrc"
              @click="
                imageSrc = null;
                form.doctor_image = null;
              "
              class="
                bx
                bxs-x-circle
                cursor-pointer
                rounded-full
                bg-white
                text-gray-500 text-3xl
                absolute
                left-0
                -mt-3
                -ml-2
              "
            ></i>
            <button
              @click.prevent="pickPicture"
              class="
                border-4 border-gray-500
                outline-none
                focus:outline-none
                border-dashed
                rounded-lg
                lg:rounded-md
                w-full
                h-full
                flex
                items-center
                justify-center
              "
            >
              <div v-if="!imageSrc">
                <i class="bx bxs-user text-gray-500 text-4xl"></i>
                <h3 class="text-gray-500 text-sm mt-1">
                  {{ $t("registration.individual.profileImg") }}
                </h3>
              </div>
              <img
                v-else
                class="w-full h-full object-cover"
                :src="imageSrc"
                loading="lazy"
              />
            </button>
          </div>
        </div>
        <div
          class="p-5 flex items-center justify-center w-full md:w-1/2 flex-wrap"
        >
          <label
            for="name"
            class="font-medium text-lg w-full  flex items-center"
            ><span class="text-red-600 mx-2">*</span>
            {{ $t("registration.individual.full_name") }}</label
          >
          <input
            id="name"
            name="name"
            v-model="form.name"
            type="text"
            required
            @blur="$v.form.name.$touch()"
            :class="
              defualtInput +
              ($v.form.name.$error ? ' border-red-700' : 'border-inputBorder')
            "
          />
          <p
            v-if="$v.form.name.$error"
            class="text-red-600 text-sm my-1 w-full"
          >
            {{ $t("registration.reqMsg") }}
          </p>
        </div>

        <div
          class="p-5 flex items-center justify-center w-full md:w-1/2 flex-wrap"
        >
          <label
            for="nationalities"
            class="font-medium text-lg w-full  flex items-center"
            ><span class="text-red-600 mx-2 ">*</span
            >{{ $t("registration.individual.nationality") }}</label
          >
          <v-select
            :options="nationalities"
            :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
            label="name"
            id="nationalities"
            :clearable="false"
            name="nationalities"
            v-model="form.nationality"
            :reduce="(nationality) => nationality.value"
            @blur="$v.form.nationality.$touch()"
            :class="
              defualtInput +
              ($v.form.nationality.$error
                ? ' border-red-700'
                : 'border-inputBorder')
            "
            class="p-0"
          ></v-select>
          <p
            v-if="$v.form.nationality.$error"
            class="text-red-600 text-sm my-1 w-full"
          >
            {{ $t("registration.reqMsg") }}
          </p>
        </div>
        <div
          v-if="form.nationality == 'Libyan'"
          class="p-5 flex items-center justify-center w-full md:w-1/2 flex-wrap"
        >
          <label
            for="id_number"
            class="font-medium text-lg w-full  flex items-center"
            ><span class="text-red-600 mx-2 ">*</span>
            {{ $t("registration.individual.id") }}
          </label>
          <input
            id="id_number"
            name="id_number"
            v-model.number="form.id_number"
            type="number"
            required
            @blur="$v.form.id_number.$touch()"
            :class="
              defualtInput +
              ($v.form.id_number.$error
                ? ' border-red-700'
                : 'border-inputBorder')
            "
          />
          <p
            v-if="$v.form.id_number.$error"
            class="text-red-600 text-md font-medium w-full "
          >
            {{ $t("registration.individual.id_confrim") }}
          </p>
        </div>
        <div
          v-if="form.nationality == 'Libyan'"
          class="p-5 flex items-center justify-center w-full md:w-1/2 flex-wrap"
        >
          <label
            for="identity_number"
            class="font-medium text-lg w-full  flex items-center"
            ><span class="text-red-600 mx-2 ">*</span>
            {{ $t("registration.individual.id_or_card") }}
          </label>
          <div class="w-full lg:pl-3 flex flex-wrap">
            <input
              id="identity_number"
              name="identity_number"
              v-model="form.identity_number"
              type="text"
              required
              @blur="$v.form.identity_number.$touch()"
              :class="
                defualtInput +
                ($v.form.identity_number.$error
                  ? ' border-red-700'
                  : 'border-inputBorder')
              "
            />
            <div class="w-full flex items-center flex-wrap mt-1">
              <label class="inline-flex items-center mx-2">
                <input
                  type="radio"
                  class="form-radio"
                  name="WorkType"
                  value="جواز السفر"
                  v-model="form.type_of_identity"
                  @blur="$v.form.type_of_identity.$touch()"
                  :class="
                    $v.form.type_of_identity.$error
                      ? ' border-red-700'
                      : 'border-inputBorder'
                  "
                />
                <span class="mx-2 text-h1Text">{{
                  $t("registration.individual.passport_no")
                }}</span>
              </label>
              <label class="inline-flex items-center mx-2">
                <input
                  type="radio"
                  class="form-radio"
                  name="WorkType"
                  value="بطاقة شخصية"
                  v-model="form.type_of_identity"
                  @blur="$v.form.type_of_identity.$touch()"
                  :class="
                    $v.form.type_of_identity.$error
                      ? ' border-red-700'
                      : 'border-inputBorder'
                  "
                />
                <span class="mx-2 text-h1Text">{{
                  $t("registration.individual.card_no")
                }}</span>
              </label>
            </div>
          </div>
          <p
            v-if="$v.form.identity_number.$error"
            class="text-red-600 text-sm my-1 w-full"
          >
            {{ $t("registration.reqMsg") }}
          </p>
        </div>
        <div
          v-if="form.nationality != 'Libyan'"
          class="p-5 flex items-center justify-center w-full md:w-1/2 flex-wrap"
        >
          <label
            for="passport_copy"
            class="font-medium text-lg w-full  flex items-center"
            ><span class="text-red-600 mx-2 ">*</span>
            {{ $t("registration.individual.passport_no_doc") }}
          </label>
          <input
            class="hidden"
            ref="passport_copy"
            id="passport_copy"
            name="passport_copy"
            accept="image/*,.pdf"
            @change="passport_copyFunc"
            type="file"
            required
          />
          <div class="w-full flex items-center relative">
            <button
              @click="pickpassport_copyFunc"
              class="
                bg-primary
                rounded-r-md
                focus:outline-none
                absolute
                p-2
                z-20
                text-white
              "
            >
              {{ $t("registration.company.choose_file") }}
            </button>
            <input
              @click="pickpassport_copyFunc"
              type="text"
              @blur="$v.form.passport_copy.$touch()"
              :class="
                defualtInput +
                ($v.form.passport_copy.$error
                  ? ' border-red-700'
                  : 'border-inputBorder')
              "
              :placeholder="$t('registration.company.file_not_loaded_yet')"
              class="pr-20"
              :value="form.passport_copy ? form.passport_copy.name : null"
            />
          </div>
          <p
            v-if="$v.form.passport_copy.$error"
            class="text-red-600 text-sm my-1 w-full"
          >
            {{ $t("registration.reqMsg") }}
          </p>
        </div>

        <div
          v-if="form.nationality != 'Libyan'"
          class="p-5 flex items-center justify-center w-full md:w-1/2 flex-wrap"
        >
          <label
            for="residency_photo"
            class="font-medium text-lg w-full  flex items-center"
            ><span class="text-red-600 mx-2 ">*</span
            >{{ $t("registration.individual.residency_photo") }}</label
          >
          <input
            class="hidden"
            ref="residency_photo"
            id="residency_photo"
            name="residency_photo"
            accept="image/*,.pdf"
            @change="residency_photoFunc"
            type="file"
            required
          />
          <div class="w-full flex items-center relative">
            <button
              @click="pickresidency_photoFunc"
              class="
                bg-primary
                rounded-r-md
                focus:outline-none
                absolute
                p-2
                z-20
                text-white
              "
            >
              {{ $t("registration.company.choose_file") }}
            </button>
            <input
              @click="pickresidency_photoFunc"
              type="text"
              @blur="$v.form.residency_photo.$touch()"
              :class="
                defualtInput +
                ($v.form.residency_photo.$error
                  ? ' border-red-700'
                  : 'border-inputBorder')
              "
              :placeholder="$t('registration.company.file_not_loaded_yet')"
              class="pr-20"
              :value="form.residency_photo ? form.residency_photo.name : null"
            />
          </div>
          <p
            v-if="$v.form.residency_photo.$error"
            class="text-red-600 text-sm my-1 w-full"
          >
            {{ $t("registration.reqMsg") }}
          </p>
        </div>

        <div
          v-if="Branches"
          class="p-5 flex items-center justify-center w-full md:w-1/2 flex-wrap"
        >
          <label
            for="branch_id"
            class="font-medium text-lg w-full  flex items-center"
            ><span class="text-red-600 mx-2 ">*</span>
            {{ $t("registration.individual.branch") }}
          </label>
          <v-select
            :options="Branches"
            :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
            :label="$i18n.locale == 'ar' ? 'name_ar' : 'name_en'"
            id="branch_id"
            :clearable="false"
            @blur="$v.form.branch_id.$touch()"
            name="branch_id"
            v-model="form.branch_id"
            :reduce="(branch_id) => branch_id.id"
            :class="
              defualtInput +
              ($v.form.branch_id.$error
                ? ' border-red-700'
                : 'border-inputBorder')
            "
            class="p-0"
          ></v-select>
          <p
            v-if="$v.form.branch_id.$error"
            class="text-red-600 text-sm my-1 w-full"
          >
            {{ $t("registration.reqMsg") }}
          </p>
        </div>
        <div
          class="p-5 flex items-center justify-center w-full md:w-1/2 flex-wrap"
        >
          <label
            for="marital_status"
            class="font-medium text-lg w-full  flex items-center"
            ><span class="text-red-600 mx-2 ">*</span>
            {{ $t("registration.individual.marital_status") }}
          </label>
          <v-select
            :options="[
              $t('registration.individual.marital_status_options.Married'),
              $t('registration.individual.marital_status_options.divorced'),
              $t('registration.individual.marital_status_options.single'),
              $t('registration.individual.marital_status_options.Widower'),
            ]"
            id="marital_status"
            :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
            :clearable="false"
            @blur="$v.form.marital_status.$touch()"
            name="marital_status"
            v-model="form.marital_status"
            :class="
              defualtInput +
              ($v.form.marital_status.$error
                ? ' border-red-700'
                : 'border-inputBorder')
            "
            class="p-0"
          ></v-select>
          <p
            v-if="$v.form.marital_status.$error"
            class="text-red-600 text-sm my-1 w-full"
          >
            {{ $t("registration.individual.confirm_marital_status") }}
          </p>
        </div>
        <div
          class="p-5 flex items-center justify-center w-full md:w-1/2 flex-wrap"
        >
          <label
            for="birth_date"
            class="font-medium text-lg w-full flex items-center"
            ><span class="text-red-600 mx-2 ">*</span>
            {{ $t("registration.individual.birthDate") }}
          </label>
          <datetime
            id="birth_date"
            name="birth_date"
            v-model="form.birth_date"
            format="yyyy-MM-dd"
            :max-datetime="maxDate"
            @blur="$v.form.birth_date.$touch()"
            :class="
              defualtInput +
              ($v.form.birth_date.$error
                ? ' border-red-700'
                : 'border-inputBorder')
            "
            type="text"
          >
          </datetime>
          <p
            v-if="$v.form.birth_date.$error"
            class="text-red-600 text-sm my-1 w-full"
          >
            {{ $t("registration.reqMsg") }}
          </p>
        </div>
        <div
          class="p-5 flex items-center justify-center w-full md:w-1/2 flex-wrap"
        >
          <label
            for="address"
            class="font-medium text-lg w-full flex items-center"
            ><span class="text-red-600 mx-2 ">*</span>
            {{ $t("registration.individual.address") }}
          </label>
          <input
            id="address"
            name="address"
            v-model="form.address"
            type="text"
            required
            @blur="$v.form.address.$touch()"
            :class="
              defualtInput +
              ($v.form.address.$error
                ? ' border-red-700'
                : 'border-inputBorder')
            "
          />
          <p
            v-if="$v.form.address.$error"
            class="text-red-600 text-sm my-1 w-full"
          >
            {{ $t("registration.reqMsg") }}
          </p>
        </div>
        <div
          class="p-5 flex items-center justify-center w-full md:w-1/2 flex-wrap"
        >
          <label for="email" class="font-medium text-lg w-full">
            {{ $t("registration.individual.email") }}
          </label>
          <input
            id="email"
            name="email"
            v-model="form.email"
            type="email"
            required
            :class="defualtInput + 'border-inputBorder'"
          />
        </div>
        <div
          class="p-5 flex items-center justify-center w-full md:w-1/2 flex-wrap"
        >
          <label
            for="phone_number"
            class="font-medium text-lg w-full flex items-center"
            ><span class="text-red-600 mx-2 ">*</span>
            {{ $t("registration.individual.phone_number") }}
          </label>
          <input
            id="phone_number"
            name="phone_number"
            v-model="form.phone_number"
            type="number"
            required
            @blur="$v.form.phone_number.$touch()"
            :class="
              defualtInput +
              ($v.form.phone_number.$error
                ? ' border-red-700'
                : 'border-inputBorder')
            "
          />
          <p
            v-if="$v.form.phone_number.$error && !$v.form.phone_number.required"
            class="text-red-600 text-md font-medium w-full"
          >
            {{ $t("registration.reqMsg") }}
          </p>
          <p
            v-if="!$v.form.phone_number.minLength"
            class="text-red-600 text-sm my-1 w-full"
          >
            {{ $t("registration.phone_format") }}
          </p>
        </div>
      </div>
    </div>
    <div class="flex w-full lg:w-3/4 mx-auto items-center justify-end">
      <p v-if="$v.form.$error" class="text-red-600 text-md font-medium">
        {{ $t("registration.completeReqMsg") }}
      </p>
      <button
        @click="
          clear;
          $router.go(-1);
        "
        class="
          px-8
          rounded-md
          w-auto
          shadow
          py-2
          focus:outline-none
          hover:shadow-lg
          m-3
          md:m-5
          border border-primary
          text-primary
          bg-white
          text-lg
          font-medium
        "
      >
        {{ $t("registration.back") }}
      </button>
      <button
        :disabled="$v.form.$error"
        @click.prevent="next"
        class="
          px-8
          rounded-md
          w-auto
          shadow
          py-2
          focus:outline-none
          hover:shadow-lg
          m-3
          md:m-5
          bg-primary
          text-white text-lg
          font-medium
        "
      >
        {{ $t("registration.next") }}
      </button>
    </div>
  </div>
</template>
<script>
import {
  required,
  numeric,
  minLength,
  email,
  maxLength,
} from "vuelidate/lib/validators";
import moment from "moment";
import nationalities from "../../../assets/nationalities.js";
export default {
  name: "individualGeneralInfo",
  data() {
    return {
      nationalities: nationalities,
      url: process.env.VUE_APP_URL,
      defualtInput:
        "p-2  bg-white rounded-md border w-full  focus-within:border-primary hover:border-primary focus:border-primary focus:outline-none focus:shadow-md my-1",
      maxDate: moment().format("L"),
      imageSrc: null,
      SearchText: "",
      form: {
        doctor_image: "",
        name: "",
        birth_date: "",
        id_number: "",
        type_of_identity: "",
        identity_number: "",
        marital_status: "",
        address: "",
        email: "",
        phone_number: "",
        branch_id: "",
        nationality: "Libyan",
        passport_copy: "",
        residency_photo: "",
      },
    };
  },
  validations() {
    if (this.form.nationality == "Libyan") {
      return {
        form: {
          branch_id: { required },
          name: { required },
          nationality: { required },
          birth_date: { required },
          email: { email },
          id_number: {
            required,
            numeric,
            minLength: minLength(12),
            maxLength: maxLength(12),
          },
          type_of_identity: { required },
          identity_number: { required },
          marital_status: { required },
          address: { required },
          phone_number: { required, minLength: minLength(10) },
        },
      };
    } else if (this.form.nationality != "Libyan") {
      return {
        form: {
          branch_id: { required },
          name: { required },

          nationality: { required },
          email: { email },
          birth_date: { required },
          marital_status: { required },
          address: { required },
          phone_number: { required, minLength: minLength(10) },
          passport_copy: { required },
          residency_photo: { required },
        },
      };
    }
  },
  computed: {
    userData() {
      return this.$store.getters.userData;
    },
    Branches() {
      return this.$store.getters.getBranches;
    },
    individualData() {
      return this.$store.getters.individualData;
    },
    SavedImageSrc() {
      return this.$store.getters.SavedImageSrc;
    },
  },
  created() {
    this.$store.dispatch("getBranches");
    if (this.individualData) {
      this.form = { ...this.form, ...this.individualData };
    }
    if (this.SavedImageSrc) {
      this.imageSrc = this.SavedImageSrc;
    }
    window.onpopstate = () => {
      if (this.$route.name == "Home" || this.$route.name == "DoctorsPanel") {
        this.clear();
      }
    };
    if (this.userData) {
      if (this.userData.status !== "super-admin") {
        this.form.branch_id = this.userData.branch_id;
      }
    }
  },
  methods: {
    clear() {
      this.$store.commit("editDoctor", null);
      this.$store.commit("SavedImageSrc", null);
      this.$store.commit("individualFormFilling", null);
    },
    pickPicture() {
      this.$refs.uploadImg.click();
    },
    OnPickerChange(e) {
      if (!e.target.files.length) return;
      this.form.doctor_image = e.target.files[0];
      this.imageSrc = URL.createObjectURL(e.target.files[0]);
    },
    pickpassport_copyFunc() {
      this.$refs.passport_copy.click();
    },
    pickresidency_photoFunc() {
      this.$refs.residency_photo.click();
    },
    passport_copyFunc(e) {
      if (!e.target.files.length) return;

      const file = e.target.files[0].type;
      if (
        file == "image/png" ||
        file == "application/pdf" ||
        file == "image/jpeg" ||
        file == "image/jpg"
      ) {
        this.form.passport_copy = e.target.files[0];
      } else {
        let message = [this.$t("registration.company.imgValidation")];
        this.$store.commit("toggleErrorMsg", message);
      }
    },
    residency_photoFunc(e) {
      if (!e.target.files.length) return;
      const file = e.target.files[0].type;
      if (
        file == "image/png" ||
        file == "application/pdf" ||
        file == "image/jpeg" ||
        file == "image/jpg"
      ) {
        this.form.residency_photo = e.target.files[0];
      } else {
        let message = [this.$t("registration.company.imgValidation")];
        this.$store.commit("toggleErrorMsg", message);
      }
    },
    next() {
      this.$v.form.$touch();
      console.log(this.$v.$error);
      if (this.$v.form.$pending || this.$v.form.$error) return;

      this.$store.commit("individualFormFilling", this.form);
      this.$store.commit("SavedImageSrc", this.imageSrc);
      this.$router.push({ name: "Education" });
    },
  },
};
</script>
