import indivual from '@/views/Registration/Indivual'
import company from '@/views/Registration/Company'
import Labs from '@/views/Registration/Labs'

import indivualGeneral from '@/components/Registration/individual/General'
import Education from '@/components/Registration/individual/Education'

import companyGeneral from '@/components/Registration/company/general'
import companyPersonal from '@/components/Registration/company/personal'
import companyDocuments from '@/components/Registration/company/documents'

const registrationRoutes = [
  {
    path: '/',
    name: 'indivual',
    component: indivual,
    children: [
      {
        path: '/personal-data',
        name: 'indivualGeneral',
        component: indivualGeneral
      },
      {
        path: '/experience',
        name: 'Education',
        component: Education
      }
    ]
  },
  {
    path: '/',
    name: 'company',
    component: company,
    children: [
      {
        path: '/general-information',
        name: 'companyGeneral',
        component: companyGeneral
      },
      {
        path: '/location',
        name: 'companyPersonal',
        component: companyPersonal
      },
      {
        path: '/Documents',
        name: 'companyDocuments',
        component: companyDocuments
      }
    ]
  },
  {
    path: '/lab-registration',
    name: 'Labs',
    component: Labs
  }
]
export default registrationRoutes
