<template>
	<nav
		class="absolute w-full bg-transparent"
		style="z-index: 9999"
		:class="toggle ? 'bg-white lg:bg-transparent' : 'bg-transparent'"
	>
		<div
			:class="toggle ? 'py-5' : null"
			class="container mx-auto px-5 lg:px-8 flex items-center justify-between text-white my-3 noneBlurTilte"
		>
			<button
				@click="
					routName != 'Home' ? $router.push({ name: 'Home' }) : null;
					toggle = false;
				"
				class="z-50 outline-none focus:outline-none"
			>
				<img
					v-if="!toggle"
					class="cursor-pointer object-cover"
					width="65"
					loading="lazy"
					height="65"
					alt="نقابة طب المختبرات الليبية"
					src="../../assets/imgs/libyan_labs_logo_dark_bg_withtext.png"
				/>
				<img
					v-if="toggle"
					class="cursor-pointer"
					width="65"
					loading="lazy"
					height="65"
					alt="نقابة طب المختبرات الليبية"
					@click="toggle = !toggle"
					src="../../assets/imgs/libyan_labs_logo_white_bg_withtext.png"
				/>
			</button>
			<ul
				:style="toggle ? 'top: 0px' : 'top: -130vh'"
				class="absolute block inset-0 lg:static text-center h-screen w-screen transition-all duration-1000 ease-in-out bg-white lg-text-auto pt-20 lg:pt-0 lg:bg-transparent lg:h-auto lg:w-auto lg:flex items-center justify-around text-newsDescription lg:text-links tracking-wide"
			>
				<li
					class="mx-3 text-md lg:text-md my-5 lg:my-0 tracking-wide"
					:class="
						routName == 'Home'
							? 'text-newsDescription lg:text-white font-bold'
							: 'text-newsDescription lg:text-links'
					"
				>
					<button
						@click="
							$router.push({ name: 'Home' });
							toggle = false;
						"
						class="focus:outline-none py-2"
					>
						{{ $t('navbar.home') }}
					</button>
					<span v-if="routName == 'Home'" class="block w-12 h-1 rounded-lg bg-info mx-auto -mt-1"></span>
				</li>
				<li
					class="mx-3 text-md lg:text-md my-5 lg:my-0 tracking-wide"
					:class="
						routName == 'About'
							? 'text-newsDescription lg:text-white font-bold'
							: 'text-newsDescription lg:text-links'
					"
				>
					<button
						@click="
							$router.push({ name: 'About' });
							toggle = false;
						"
						class="focus:outline-none py-2"
					>
						{{ $t('navbar.about') }}
					</button>
					<span v-if="routName == 'About'" class="block w-12 h-1 rounded-lg bg-info mx-auto -mt-1"></span>
				</li>
				<li
					class="mx-3 text-newsDescription  text-md lg:text-md my-5 lg:my-0 tracking-wide"
					:class="
						routName == 'Services'
							? 'text-newsDescription lg:text-white font-bold'
							: 'text-newsDescription lg:text-links'
					"
				>
					<button
						@click="
							$router.push({ name: 'Services' });
							toggle = false;
						"
						class="focus:outline-none py-2"
					>
						{{ $t('navbar.services') }}
					</button>
					<span v-if="routName == 'Services'" class="block w-12 h-1 rounded-lg bg-info mx-auto -mt-1"></span>
				</li>
				<li
					class="mx-3 text-newsDescription text-md lg:text-md my-5 lg:my-0 tracking-wide"
					:class="
						routName == 'News'
							? 'text-newsDescription lg:text-white font-bold'
							: 'text-newsDescription lg:text-links'
					"
				>
					<button
						@click="
							$router.push({ name: 'News' });
							toggle = false;
						"
						class="focus:outline-none py-2"
					>
						{{ $t('navbar.news') }}
					</button>
					<span v-if="routName == 'News'" class="block w-12 h-1 rounded-lg bg-info mx-auto -mt-1"></span>
				</li>
				<li
					class="mx-3 text-newsDescription text-md lg:text-md my-5 lg:my-0 tracking-wide"
					:class="
						routName == 'Branches'
							? 'text-newsDescription lg:text-white font-bold'
							: 'text-newsDescription lg:text-links'
					"
				>
					<button
						@click="
							$router.push({ name: 'Branches' });
							toggle = false;
						"
						class="focus:outline-none py-2"
					>
						{{ $t('navbar.Branches') }}
					</button>
					<span v-if="routName == 'Branches'" class="block w-12 h-1 rounded-lg bg-info mx-auto -mt-1"></span>
				</li>
				<li
					class="mx-3 text-newsDescription text-md lg:text-md my-5 lg:my-0 tracking-wide"
					:class="
						routName == 'Events'
							? 'text-newsDescription lg:text-white font-bold'
							: 'text-newsDescription lg:text-links'
					"
				>
					<button
						@click="
							$router.push({ name: 'Events' });
							toggle = false;
						"
						class="focus:outline-none py-2"
					>
						{{ $t('navbar.events') }}
					</button>
					<span v-if="routName == 'Events'" class="block w-12 h-1 rounded-lg bg-info mx-auto -mt-1"></span>
				</li>
				<li
					class="mx-3 text-newsDescription text-md lg:text-md my-5 lg:my-0 tracking-wide"
					:class="
						routName == 'Structure'
							? 'text-newsDescription lg:text-white font-bold'
							: 'text-newsDescription lg:text-links'
					"
				>
					<button
						@click="
							$router.push({ name: 'Structure' });
							toggle = false;
						"
						class="focus:outline-none py-2"
					>
						{{ $t('navbar.organizationalStructure') }}
					</button>
					<span v-if="routName == 'Structure'" class="block w-12 h-1 rounded-lg bg-info mx-auto -mt-1"></span>
				</li>
				<li
					class="mx-3 text-newsDescription text-md lg:text-md my-5 lg:my-0 tracking-wide"
					:class="
						routName == 'Contact'
							? 'text-newsDescription lg:text-white font-bold'
							: 'text-newsDescription lg:text-links'
					"
				>
					<button
						@click="
							$router.push({ name: 'Contact' });
							toggle = false;
						"
						class="focus:outline-none py-2"
					>
						{{ $t('navbar.contact') }}
					</button>
					<span v-if="routName == 'Contact'" class="block w-12 h-1 rounded-lg bg-info mx-auto -mt-1"></span>
				</li>
				<li
					class="lg:hidden mx-3 text-newsDescription lg:text-links text-md lg:text-md my-5 lg:my-0 tracking-wide"
				>
					<button
						@click="toggleLocale"
						class="focus:outline-none bg-secondry text-white px-8 py-2 mx-auto font-bold block"
					>
						{{ $i18n.locale == 'EN' ? 'عربي' : 'EN' }}
					</button>
				</li>
			</ul>
			<button @click="toggleLocale" class="focus:outline-none font-lg font-bold hidden lg:block">
				{{ $i18n.locale == 'EN' ? 'عربي' : 'EN' }}
			</button>

			<div @click="toggle = !toggle" class="z-50 lg:hidden  ml-5">
				<i v-if="!toggle" class="bx bx-menu text-white text-3xl"></i>
				<i v-else class="bx bx-x text-primary text-4xl"></i>
				<!-- <span
					:class="toggle ? 'absolute a bg-primary' : 'm-1 bg-white'"
					class="transition-all duration-700 ease-in-out w-full h-1 rounded-lg"
				></span>
				<span
					:class="toggle ? 'absolute b bg-primary' : 'm-1 bg-white'"
					class="transition-all duration-700 ease-in-out w-full h-1 rounded-lg"
				></span> -->
			</div>
		</div>
	</nav>
</template>
<script>
export default {
	name: 'navbar',
	data() {
		return {
			toggle: false,
		};
	},
	methods: {
		toggleLocale() {
			if (this.$i18n.locale == 'EN') {
				this.$i18n.locale = 'ar';
				localStorage.setItem('local', this.$i18n.locale);
			} else {
				this.$i18n.locale = 'EN';
				localStorage.setItem('local', this.$i18n.locale);
			}
		},
	},
	computed: {
		routName() {
			return this.$route.name;
		},
	},
	watch: {
		toggle() {
			if (this.toggle) {
				setTimeout(() => {
					this.toggle = false;
				}, 8000);
			}
		},
	},
};
</script>
