<template>
	<transition name="slideTop">
		<div
			v-if="animate"
			class="flex justify-between items-center m-1 font-medium py-1 px-2 rounded-md text-green-100 bg-green-700 border border-green-700"
		>
			<div class="flex items-center">
				<div>
					<svg
						xmlns="http://www.w3.org/2000/svg"
						width="100%"
						height="100%"
						fill="none"
						viewBox="0 0 24 24"
						stroke="currentColor"
						stroke-width="2"
						stroke-linecap="round"
						stroke-linejoin="round"
						class="feather feather-check-circle w-5 h-5 mx-2"
					>
						<path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path>
						<polyline points="22 4 12 14.01 9 11.01"></polyline>
					</svg>
				</div>
				<div class="text-xl font-normal text-right">
					<h1 class="py-2 font-medium">
						{{ $i18n.locale == 'ar' ? ' تم استلام طلبك بنجاح' : "We've successfully recived your order" }}
						<p class="text-sm font-base">
							{{ successMsg }}
						</p>
					</h1>
				</div>
			</div>
			<button
				@click="toggleSuccessMsg"
				class="bg-white text-green-700 focus:outline-none font-medium mx-3 text-lg rounded-md px-3 py-1"
			>
				{{ $i18n.locale == 'ar' ? 'حسناً' : 'OK' }}
			</button>
		</div>
	</transition>
</template>
<script>
export default {
	data() {
		return {
			animate: false,
		};
	},
	mounted() {
		setTimeout(() => {
			this.animate = true;
		}, 10);
		document.addEventListener('keyup', (evt) => {
			if (evt.keyCode === 27) {
				this.toggleSuccessMsg();
			}
		});
		if (this.successMsg) {
			setTimeout(() => {
				this.toggleSuccessMsg();
			}, 5000);
		}
	},
	methods: {
		toggleSuccessMsg() {
			this.$store.commit('toggleSuccessMsg', null);
			this.$router.push({ name: this.userData ? 'Dashboard' : 'Home' });
		},
	},
	computed: {
		userData() {
			return this.$store.getters.userData;
		},
		successMsg() {
			return this.$store.getters.successMsg;
		},
	},
};
</script>
