import axios from "@/axios";
import moment from "moment";
const newRegistration = {
  state: () => ({
    individualData: null,
    companyData: null,
    SavedImageSrc: null,
  }),
  mutations: {
    individualFormFilling(state, payload) {
      if (payload) {
        state.individualData = {
          ...state.individualData,
          ...payload,
        };
      } else {
        state.individualData = null;
      }
    },
    SavedImageSrc(state, img) {
      state.SavedImageSrc = img;
    },
    companyFormFilling(state, payload) {
      if (payload) {
        state.companyData = { ...state.companyData, ...payload };
      } else {
        state.companyData = null;
      }
    },
  },
  actions: {
    submitIndividual({ state }) {
      const formData = new FormData();

      const newForm = {
        ...state.individualData,
        birth_date: moment(state.individualData.birth_date).format(
          "YYYY/MM/DD"
        ),
        date_of_hiring: state.individualData.date_of_hiring
          ? moment(state.individualData.date_of_hiring).format("YYYY/MM/DD")
          : "",
        date_of_joining: state.individualData.date_of_joining
          ? moment(state.individualData.date_of_joining).format("YYYY/MM/DD")
          : "",
        year_of_graduation: moment(
          state.individualData.year_of_graduation
        ).format("YYYY/MM/DD"),
      };
      if (newForm.nationality == "Libyan") {
        delete newForm.passport_copy;
        delete newForm.residency_photo;
      } else {
        delete newForm.id_number,
          delete newForm.type_of_identity,
          delete newForm.identity_number;
      }

      for (const [key, value] of Object.entries(newForm)) {
        formData.append(key, value);
      }

      return new Promise((resolve, reject) => {
        axios
          .post("/api/doctors", formData, {
            headers: {
              "content-type": "multipart/form-data",
            },
          })
          .then((res) => {
            resolve(res);
            state.individualData = null;
          })
          .catch((err) => {
            reject(err.response.data);
          });
      });
    },
    labsSubmiting({ state }, payload) {
      if (!state) return;
      return new Promise((resolve, reject) => {
        axios({
          url: "/api/labs",
          method: "post",
          data: { ...payload },
        })
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            reject(err.response.data);
          });
      });
    },
    companySubmiting({ state }) {
      const formData = new FormData();

      const newForm = {
        ...state.companyData,
        registration_date: moment(state.companyData.registration_date).format(
          "YYYY/MM/DD"
        ),
      };

      for (const [key, value] of Object.entries(newForm)) {
        formData.append(key, value);
      }
      return new Promise((resolve, reject) => {
        axios({
          url: "/api/companies",
          method: "post",
          headers: {
            "content-type": "multipart/form-data",
          },
          data: formData,
        })
          .then((res) => {
            resolve(res);
            state.companyData = null;
          })
          .catch((err) => {
            reject(err.response.data);
          });
      });
    },

    renew_req_doctor(context, payload) {
      if (!context.state) return;
      return new Promise((resolve, reject) => {
        axios
          .post("/api/membership/renewalDoctorRequest", { id: +payload })
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            reject(err.response);
          });
      });
    },
    renew_req_company(context, payload) {
      if (!context.state) return;
      return new Promise((resolve, reject) => {
        axios
          .post("/api/membership/renewalCompanyRequest", { id: +payload })
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            reject(err.response);
          });
      });
    },
    renew_req_lab(context, payload) {
      if (!context.state) return;
      return new Promise((resolve, reject) => {
        axios
          .post("/api/membership/renewalLabRequest", { id: +payload })
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            reject(err.response);
          });
      });
    },
  },
  getters: {
    individualData(state) {
      return state.individualData;
    },
    companyData(state) {
      return state.companyData;
    },
    SavedImageSrc(state) {
      return state.SavedImageSrc;
    },
  },
};

export default newRegistration;
