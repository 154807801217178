/** @format */

import axios from "axios";

//const baseURL = 'http://doctors-syndicate.herokuapp.com'
const baseURL = "https://api.llms.ly";
//const baseURL = "http://41.208.74.84"

axios.defaults.withCredentials = true;

axios.defaults.headers.accept = "application/json";

axios.defaults.headers["X-Requested-With"] = "XMLHttpRequest";

export default axios.create({
  baseURL,
  // You can add your headers here
});
