import Vue from 'vue';
import Vuex from 'vuex';
import axios from '@/axios';

Vue.use(Vuex);
import getData from './getData';
import newRegistration from './newRegistration';

export default new Vuex.Store({
	state: {
		successMsg: null,
		errorMsg: null,
	},
	mutations: {
		toggleSuccessMsg: (state, payload) => {
			state.successMsg = payload;
		},
		toggleErrorMsg: (state, payload) => {
			state.errorMsg = payload;
		},
	},
	actions: {
		subscription(_, email) {
			return new Promise((resolve, reject) => {
				axios
					.post('/api/subscription', email)
					.then(({ data }) => {
						resolve(data);
					})
					.catch((err) => reject(err.response));
			});
		},
	},
	getters: {
		successMsg(state) {
			return state.successMsg;
		},
		errorMsg(state) {
			return state.errorMsg;
		},
	},
	modules: {
		getData,
		newRegistration,
	},
});
